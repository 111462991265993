import React from 'react';
import { InputBase, Tooltip, Typography, useTheme } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { changeWorkspaceName } from '@prbx/firebase/workspace';
import { User } from 'firebase/auth';
import toast from 'react-hot-toast';

import { TypographyProps } from '@mui/material/Typography';
import { ERROR_WORKSPACE } from '@prbx/consts';

type EditableWorkspaceNameProps = TypographyProps & {
    id: string;
    name: string;
    isEditing: boolean;
    setIsEditing: (boolean: boolean) => void;
    user: User;
};

export const workspaceNameRegex = new RegExp('(.|\\s)*\\S(.|\\s)*');

const EditableWorkspaceName = ({
    id,
    name,
    isEditing,
    setIsEditing,
    user,
    ...typographyProps
}: EditableWorkspaceNameProps) => {
    const theme = useTheme();
    const [workspaceName, setWorkspaceName] = useState(name);
    const [oldName, setOldName] = useState('');
    const nameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setWorkspaceName(name);
    }, [name]);

    useEffect(() => {
        nameRef.current?.focus();
        setOldName(workspaceName);
    }, [nameRef, isEditing]);

    const finishEditing = () => {
        if (!workspaceNameRegex.test(workspaceName)) {
            setWorkspaceName(oldName);
        } else if (workspaceName == oldName) {
        } else if (workspaceName.length > 32) {
            toast.error(ERROR_WORKSPACE.WORKSPACE_NAME_TOO_LONG);
            setWorkspaceName(oldName);
        } else {
            toast(ERROR_WORKSPACE.CHANGED_WORKSPACE_NAME);
            changeWorkspaceName(user, id, workspaceName);
        }
        setIsEditing(false);
    };

    const handleKeyDown = (event) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            nameRef.current?.blur();
        }
        if (event.key === 'Escape') {
            setWorkspaceName(oldName);
            setIsEditing(false);
        }
    };

    if (isEditing) {
        return (
            <InputBase
                // Focus handling
                inputRef={nameRef}
                onFocus={(event) => {
                    event.target.select();
                }}
                onBlur={finishEditing}
                // Input handling
                onKeyDown={handleKeyDown}
                onChange={(e) => setWorkspaceName(e.target.value)}
                // Visual
                value={workspaceName}
                sx={{
                    typography: typographyProps.variant ?? 'body',
                    '& input': {
                        padding: 0,
                    },
                    borderBottom: `2px solid ${theme.palette.text.primary}`,
                }}
                fullWidth
                data-testid="editableworkspacename-input"
            />
        );
    }

    const handleDoubleClick = (event) => {
        event.stopPropagation();
        setIsEditing(true);
    };

    return (
        <Tooltip title="Rename">
            <Typography
                onDoubleClick={handleDoubleClick}
                {...typographyProps}
                data-testid="editableworkspacename-name"
            >
                {workspaceName}
            </Typography>
        </Tooltip>
    );
};

export default EditableWorkspaceName;
