import React from 'react';
import { styled } from '@mui/material';
import { Handle, useReactFlow } from 'reactflow';
import { isValidEdgeConnection } from '@prbx/utils/edge';

const StyledHandleComponent = styled(Handle)`
    width: 8px;
    height: 8px;
    transform-origin: right;
    transition: transform 0.2s ease-in-out;

    &.react-flow__handle-connecting {
        transform: scale(2);
        background: ${(props) => props.theme.palette.error.main};
    }

    &.react-flow__handle-valid {
        transform: scale(2);
        background: ${(props) => props.theme.palette.success.main};
    }
`;

export const StyledHandle = (props) => {
    const instance = useReactFlow();

    return (
        <StyledHandleComponent
            isValidConnection={(connection) =>
                isValidEdgeConnection(instance, connection)
            }
            {...props}
        />
    );
};
