import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Box,
} from '@mui/material';
import {
    getSharedWithUids,
    inviteUserToWorkspace,
    isWorkspaceOwner,
    WorkspaceDetail,
} from '@prbx/firebase/workspace';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { APP_DOMAIN, ERROR_INVITE_USER, ROUTES } from '@prbx/consts';
import LinkIcon from '@mui/icons-material/Link';
import toast from 'react-hot-toast';
import { getUid } from '@prbx/firebase/auth';
import InvitedUser from '@prbx/components/Dashboard/InviteWorkspaceDialog/InvitedUser';
import { useAuthState } from 'react-firebase-hooks/auth';
import { mAuth } from '@prbx/firebase/firebase';

type InviteWorkspaceDialogProps = {
    open: boolean;
    close: () => void;
    id: string;
    workspaceDetail: WorkspaceDetail;
};

const InviteWorkspaceDialog = ({
    open,
    close,
    id,
    workspaceDetail,
}: InviteWorkspaceDialogProps) => {
    const [user] = useAuthState(mAuth);
    const [userToInvite, setUserToInvite] = useState('');
    const [error, setError] = useState<ERROR_INVITE_USER | null>(null);
    const sharedWith = getSharedWithUids(workspaceDetail.sharedWith);

    const handleInviteUser = async () => {
        const uidToInvite = await getUid(userToInvite);
        setError(null);

        // ERROR_INVITE_USER.USER_NOT_EXIST
        // Username does not exist in system
        if (!uidToInvite) {
            setError(ERROR_INVITE_USER.USER_NOT_EXIST);
            return;
        }

        // ERROR_INVITE_USER.CANNOT_INVITE_CREATOR
        // Can't invite yourself!
        const isWorkspaceOwnerCheck = await isWorkspaceOwner(id, uidToInvite);
        if (isWorkspaceOwnerCheck) {
            setError(ERROR_INVITE_USER.CANNOT_INVITE_CREATOR);
            return;
        }

        // ERROR_INVITE_USER.USER_ALREADY_INVITED
        // User is already in the sharedWith for this worksapce
        if (
            typeof workspaceDetail.sharedWith === 'object' &&
            sharedWith.includes(uidToInvite)
        ) {
            setError(ERROR_INVITE_USER.USER_ALREADY_INVITED);
            return;
        }

        inviteUserToWorkspace(id, uidToInvite).then(() =>
            toast(`Added ${userToInvite} to the workspace`)
        );
    };

    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="invite-to-workspace"
        >
            <DialogTitle noWrap>
                Invite to &apos;{workspaceDetail.name}&apos;
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        mb: 2,
                    }}
                >
                    <TextField
                        fullWidth
                        placeholder="Enter username"
                        onChange={(e) => setUserToInvite(e.target.value)}
                        sx={{ mr: 1, flex: 1 }}
                        error={error !== null}
                        helperText={
                            error == ERROR_INVITE_USER.USER_ALREADY_INVITED
                                ? ERROR_INVITE_USER.USER_ALREADY_INVITED
                                : error == ERROR_INVITE_USER.USER_NOT_EXIST
                                ? ERROR_INVITE_USER.USER_NOT_EXIST
                                : error ==
                                  ERROR_INVITE_USER.CANNOT_INVITE_CREATOR
                                ? ERROR_INVITE_USER.CANNOT_INVITE_CREATOR
                                : ''
                        }
                        data-testid="inviteworkspacedialog-enter-username"
                    />
                    <Button
                        onClick={handleInviteUser}
                        startIcon={<PersonAddIcon />}
                        sx={{ height: 56 }}
                        data-testid="inviteworkspacedialog-invite-user"
                    >
                        Invite user
                    </Button>
                </Box>
                <DialogContentText mb={2}>People with access</DialogContentText>
                {user && <InvitedUser id={id} uid={user.uid} owner />}
                {sharedWith.length > 0 &&
                    sharedWith.map((uid) => {
                        return (
                            <div key={uid}>
                                <InvitedUser id={id} uid={uid} />
                            </div>
                        );
                    })}
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Button
                    startIcon={<LinkIcon />}
                    variant="outlined"
                    onClick={() => {
                        navigator.clipboard.writeText(
                            `${APP_DOMAIN}${ROUTES.WORKSPACE}${id}`
                        );
                        toast('Link copied to clipboard');
                    }}
                    data-testid="inviteworkspacedialog-copy-link"
                >
                    Copy link
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        close();
                    }}
                    data-testid="inviteworkspacedialog-done"
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InviteWorkspaceDialog;
