const generatePropertiesLabel = (properties: string[]): string => {
    if (properties.length <= 3) {
        return properties.join(', ');
    } else {
        const firstThree = properties.slice(0, 3).join(', ');
        const remaining = properties.length - 3;
        return `${firstThree} and ${remaining} more ${
            remaining === 1 ? 'property' : 'properties'
        }`;
    }
};

export default generatePropertiesLabel;
