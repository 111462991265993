import { ComponentNodeType, FlowNode } from '@prbx/types/Nodes';
import { useContext, useMemo } from 'react';
import { Edge, getConnectedEdges, useReactFlow } from 'reactflow';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import { getParentStyles } from '@prbx/utils/styles';
import { getUpstreamComponent } from '@prbx/utils/upstreamAttributes';

const useComponent = (
    id: string
): {
    component:
        | React.ComponentType<React.HTMLAttributes<HTMLElement>>
        | undefined;
    styles: React.CSSProperties | undefined;
    inheritingFrom: FlowNode | undefined;
} => {
    // Import getNode, optimised node and edge arrays, and fetch component node
    const { getNode } = useReactFlow();
    const { sNodes, sEdges } = useContext(FlowContext);
    const node = useMemo(
        () =>
            (sNodes.find((n) => n.id === id) as ComponentNodeType) ??
            getNode(id),
        [sNodes, id]
    );

    // Set connected edges to component node
    const connectedEdges = useMemo(
        () =>
            getConnectedEdges([node], sEdges as Edge[]).filter(
                (edge) => edge.target === node.id
            ),
        [node, sEdges, id]
    );

    // Determine component node that is inheriting from another component node
    const inheritingFrom = useMemo(() => {
        const edge = connectedEdges.find((edge) => {
            const sourceNode = getNode(edge.source);
            return sourceNode?.type === 'component';
        });
        return edge ? getNode(edge.source) : undefined;
    }, [connectedEdges, id]);

    // Set component to render
    const component = useMemo(
        () =>
            getUpstreamComponent(
                node,
                connectedEdges,
                getNode,
                sNodes as FlowNode[],
                sEdges
            ),
        [node, connectedEdges, getNode, sNodes, sEdges]
    );

    // Set styles to render
    const styles = useMemo(
        () =>
            getParentStyles(
                connectedEdges,
                getNode,
                sNodes as FlowNode[],
                sEdges
            ),
        [connectedEdges, getNode, sNodes, sEdges]
    );

    return { component, styles, inheritingFrom };
};

export default useComponent;
