import React, { useContext, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogContentText,
} from '@mui/material';
import { Connection, Edge, useReactFlow } from 'reactflow';
import { CoreNodeData, FlowNode, NodeType } from '@prbx/types/Nodes';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import EdgePropertiesMultiSelect from '@prbx/components/FlowEditor/ElementEditor/EdgeEditor/EdgePropertiesMultiSelect';
import getFormattedNodeName from '@prbx/utils/getFormattedNodeName';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import { getUpstreamCoreToken } from '@prbx/utils/upstreamAttributes';

type AddComponentPropertyDialogProps = {
    open: boolean;
    close: () => void;
    params: Connection;
    onEnterPropertyName: (properties: string[]) => void;
};

const AddComponentPropertyDialog = ({
    open,
    close,
    params,
    onEnterPropertyName,
}: AddComponentPropertyDialogProps) => {
    const { prefix } = useContext(WorkspaceContext);
    const { getNode } = useReactFlow();
    const { sNodes: nodes, sEdges: edges } = useContext(FlowContext);

    const sourceNode = getNode(params.source as string) as FlowNode;
    const targetNode = getNode(params.target as string) as FlowNode;

    const [properties, setProperties] = useState<string[]>([]);
    const [propertiesError, setPropertiesError] = useState<boolean>(false);

    const isUnsavedChanges = properties.length !== 0;

    let value;
    let sourceName;
    if (typeof params.source === 'string') {
        if (sourceNode) {
            const type = sourceNode.type as NodeType;
            if (type === 'core-token') {
                value = (sourceNode.data as CoreNodeData).token.value;
                sourceName = getFormattedNodeName(
                    prefix,
                    type,
                    sourceNode.data.name
                );
            } else if (
                type === 'semantic-token' ||
                type === 'component-token'
            ) {
                value = getUpstreamCoreToken(
                    sourceNode,
                    nodes as FlowNode[],
                    edges as Edge[]
                )?.data.token.value;
                sourceName = getFormattedNodeName(
                    prefix,
                    type,
                    sourceNode.data.name
                );
            }
        }
    }

    const EdgePropertiesMultiSelectComponent = () => {
        if (typeof params.target === 'string') {
            const targetNode = getNode(params.target);
            if (targetNode) {
                return (
                    <EdgePropertiesMultiSelect
                        targetNode={getNode(params.target) as FlowNode}
                        propertyValue={value}
                        state={properties}
                        error={isUnsavedChanges && propertiesError}
                        setState={setProperties}
                        setErrorState={setPropertiesError}
                    />
                );
            }
        }
        return <></>;
    };

    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="apply-token-to-component"
        >
            <DialogTitle>Apply token to component</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    Enter properties to apply <b>{sourceName}</b>
                    <br />
                    to {targetNode?.data.name} component
                </DialogContentText>
                {EdgePropertiesMultiSelectComponent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <Button
                    disabled={propertiesError}
                    onClick={() => {
                        onEnterPropertyName(properties);
                        close();
                    }}
                    data-testid="addcomponentpropertydialog-done"
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddComponentPropertyDialog;
