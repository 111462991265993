import React from 'react';
import { useReactFlow } from 'reactflow';

import ConfirmationButton from '@prbx/components/Dashboard/ConfirmationButton';
import { NodeType } from '@prbx/types/Nodes';
import { isToken } from '@prbx/utils/node';

type DeleteNodeProps = {
    id: string;
    type: NodeType;
};

const DeleteNode = ({ id, type }: DeleteNodeProps) => {
    const { deleteElements, getNode } = useReactFlow();
    const nodeToDelete = getNode(id);

    const handleDelete = () => {
        if (nodeToDelete) {
            deleteElements({ nodes: [nodeToDelete] });
        }
    };

    const nodeIsToken = isToken(type);
    const typeText = nodeIsToken ? 'token' : 'component';

    return (
        <ConfirmationButton
            actionText={`delete the ${typeText}`}
            variant="outlined"
            onConfirm={handleDelete}
            color="error"
            fullWidth
        >
            Delete {typeText}
        </ConfirmationButton>
    );
};

export default DeleteNode;
