import React from 'react';
import { Theme } from '@mui/material';
import {
    Edge,
    Connection,
    MarkerType,
    ReactFlowInstance,
    getConnectedEdges,
} from 'reactflow';

import { FlowNode } from '@prbx/types/Nodes';
import getAllIncomers from '@prbx/utils/getAllIncomers';
import generatePropertiesLabel from '@prbx/utils/generatePropertiesLabel';
import { EdgeData } from '@prbx/types/Edges';

const willCreateLoop = (
    sourceNode: FlowNode,
    targetNode: FlowNode,
    nodes: FlowNode[],
    edges: Edge[]
) =>
    getAllIncomers(sourceNode, nodes, edges).some(
        (nd) => nd.id === targetNode.id
    );

export const TESTING_willCreateLoop = willCreateLoop;

const edgeAlreadyExists = (
    sourceId: string,
    targetId: string,
    edges: Edge[]
) => {
    const edgeId = `${sourceId}->${targetId}`;
    const alreadyExistingEdges = edges.filter((edge) => edge.id === edgeId);
    return alreadyExistingEdges.length !== 0;
};

export const TESTING_edgeAlreadyExists = edgeAlreadyExists;

export const isValidEdgeConnection = (
    instance: ReactFlowInstance,
    connection: Connection
) => {
    // Fetch instance
    const nodes = instance.getNodes() as FlowNode[];
    const edges = instance.getEdges();

    // Fetch nodes involved
    const sourceNode = instance.getNode(connection.source ?? '');
    const targetNode = instance.getNode(connection.target ?? '');

    if (sourceNode && targetNode) {
        // Define values from involved nodes
        const { type: sourceType, id: sourceId } = sourceNode;
        const { type: targetType, id: targetId } = targetNode;

        // Handle connection:
        //      core-token -> semantic-token
        //      core-token -> component-token
        //      semantic-token -> semantic-token
        //      semantic-token -> component-token
        if (
            (sourceType === 'core-token' || sourceType === 'semantic-token') &&
            (targetType === 'semantic-token' ||
                targetType === 'component-token')
        ) {
            return !(
                sourceType === 'semantic-token' &&
                willCreateLoop(sourceNode, targetNode, nodes, edges)
            );
        }

        // Handle connection:
        //      core-token -> component
        //      semantic-token -> component
        //      component-token -> component
        if (
            (sourceType === 'core-token' ||
                sourceType === 'semantic-token' ||
                sourceType === 'component-token') &&
            targetType === 'component'
        ) {
            return !edgeAlreadyExists(sourceId, targetId, edges);
        }

        // Handle connection:
        //      component -> component
        if (sourceType === 'component' && targetType === 'component') {
            if (edgeAlreadyExists(sourceId, targetId, edges)) {
                return false;
            }

            if (willCreateLoop(sourceNode, targetNode, nodes, edges)) {
                return false;
            }

            const connectedEdges = getConnectedEdges([targetNode], edges);
            const anyComponentConnections = connectedEdges.some((edge) => {
                const sourceNode = instance.getNode(edge.source);
                return (
                    sourceNode?.type === 'component' && edge.target === targetId
                );
            });
            return !anyComponentConnections;
        }
        return false;
    }
    return false;
};

export const handleEdgeConnect = (
    params: Edge<EdgeData> | Connection,
    instance: ReactFlowInstance,
    setAddPropertyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setAddPropertyParams: React.Dispatch<
        React.SetStateAction<Connection | undefined>
    >,
    theme: Theme
) => {
    if (
        typeof params === 'object' &&
        typeof params.source === 'string' &&
        typeof params.target === 'string' &&
        typeof params.sourceHandle === 'string' &&
        typeof params.targetHandle === 'string'
    ) {
        // Fetch instance functions
        const edges = instance.getEdges();
        const { addEdges, deleteElements } = instance;

        // Fetch nodes involved
        const sourceNode = instance.getNode(params.source);
        const targetNode = instance.getNode(params.target);

        // Verify nodes exist
        if (sourceNode && targetNode) {
            // Define values from involved nodes
            const { type: sourceType, id: sourceId } = sourceNode;
            const { type: targetType, id: targetId } = targetNode;

            // Handle connection:
            //      core-token -> semantic-token
            //      core-token -> component-token
            //      semantic-token -> semantic-token
            //      semantic-token -> component-token
            if (
                (sourceType === 'core-token' ||
                    sourceType === 'semantic-token') &&
                (targetType === 'semantic-token' ||
                    targetType === 'component-token') &&
                isValidEdgeConnection(instance, params as Connection)
            ) {
                // Delete edge if it already exists
                const connectedEdges = getConnectedEdges(
                    [targetNode],
                    edges
                ).filter((edge) => edge.target === targetId);
                if (connectedEdges.length !== 0) {
                    deleteElements({ edges: connectedEdges });
                }

                // Create edge
                const edgeId = `${sourceId}->${targetId}`;
                const newEdge: Edge = {
                    id: edgeId,
                    source: params.source,
                    target: params.target,
                    sourceHandle: params.sourceHandle,
                    targetHandle: params.targetHandle,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 16,
                        height: 16,
                    },
                    style: {
                        strokeWidth: 2,
                    },
                };
                addEdges(newEdge);
            }

            // Handle connection:
            //      core-token -> component
            //      semantic-token -> component
            //      component-token -> component
            if (
                (sourceType === 'core-token' ||
                    sourceType === 'semantic-token' ||
                    sourceType === 'component-token') &&
                targetType === 'component' &&
                isValidEdgeConnection(instance, params as Connection)
            ) {
                // New edge needs to be created, serve dialog and handoff
                setAddPropertyDialogOpen(true);
                setAddPropertyParams(params as Connection);
            }

            // Handle connection:
            //      component -> component
            if (
                sourceType === 'component' &&
                targetType === 'component' &&
                isValidEdgeConnection(instance, params as Connection)
            ) {
                // Create edge
                const edgeId = `${sourceId}->${targetId}`;
                const newEdge: Edge = {
                    id: edgeId,
                    source: params.source,
                    target: params.target,
                    sourceHandle: params.sourceHandle,
                    targetHandle: params.targetHandle,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 16,
                        height: 16,
                        color: theme.palette.primary.main,
                    },
                    style: {
                        stroke: theme.palette.primary.main,
                        strokeWidth: 2,
                    },
                };
                addEdges(newEdge);
            }
        }
    }
};

export const addPropertyEdge = (
    properties: string[],
    addPropertyParams: Edge<EdgeData> | Connection,
    instance: ReactFlowInstance,
    theme: Theme
) => {
    const params = addPropertyParams;
    if (
        typeof params === 'object' &&
        typeof params.source === 'string' &&
        typeof params.target === 'string' &&
        typeof params.sourceHandle === 'string' &&
        typeof params.targetHandle === 'string'
    ) {
        // Fetch instance
        const addEdges = instance.addEdges;

        // Fetch nodes involved
        const sourceNode = instance.getNode(params.source);
        const targetNode = instance.getNode(params.target);

        // Verify nodes exist
        if (sourceNode && targetNode) {
            // Define values from involved nodes
            const { id: targetId } = targetNode;
            const { id: sourceId } = sourceNode;

            // Create edge
            const edgeId = `${sourceId}->${targetId}`;
            const newEdge: Edge = {
                id: edgeId,
                source: params.source,
                target: params.target,
                sourceHandle: params.sourceHandle,
                targetHandle: params.targetHandle,
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 16,
                    height: 16,
                    color: theme.palette.primary.main,
                },
                style: {
                    stroke: theme.palette.primary.main,
                    strokeWidth: 2,
                },
                label: generatePropertiesLabel(properties),
                labelBgStyle: {
                    fill: theme.palette.primary.main,
                },
                data: {
                    properties,
                },
            };
            addEdges(newEdge);
        }
    }
};
