import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import {
    getSharedWithUids,
    isWorkspaceOwner,
    Workspace,
} from '@prbx/firebase/workspace';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@prbx/consts';
import { getDisplayName } from '@prbx/firebase/auth';
import { User } from 'firebase/auth';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteWorkspaceDialog from '@prbx/components/Dashboard/DeleteWorkspaceDialog';
import EditableWorkspaceName from '@prbx/components/Dashboard/EditableWorkspaceName';
import InviteWorkspaceDialog from '@prbx/components/Dashboard/InviteWorkspaceDialog/InviteWorkspaceDialog';

type MenuItem = {
    key: string;
    onClick: () => void;
    label: string;
};

type WorkspaceProps = {
    id: string;
    workspace: Workspace;
    user: User;
};

const WorkspaceCard = ({ id, workspace, user }: WorkspaceProps) => {
    const navigate = useNavigate();

    // Destructure workspace
    const { name, lastEdited, sharedWith } = workspace;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { nodes, edges, ...workspaceDetail } = workspace;
    const sharedWithUids = getSharedWithUids(sharedWith);
    const [lastEditedTime, setLastEditedTime] = useState('');
    const [lastEditedBy, setLastEditedBy] = useState<string | null>(null);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const setDayJsTime = () => {
        setLastEditedTime(dayjs(lastEdited.time).fromNow());
    };

    // Load displayname of user that last edited
    useEffect(() => {
        const loadLastEditedBy = async () => {
            setLastEditedBy(await getDisplayName(lastEdited.by));
        };
        loadLastEditedBy();
        generateMenuItems();
    }, [workspace]);

    useEffect(() => {
        const ref = setInterval(() => setDayJsTime(), 60);
        return () => {
            clearInterval(ref);
        };
    }, [workspace]);

    const loadWorkspace = () => {
        navigate(ROUTES.WORKSPACE + id);
    };

    // Workspace context menu
    const [anchorElWorkspaceAction, setAnchorElWorkspaceAction] =
        React.useState<null | HTMLElement>(null);
    const handleOpenWorkspaceActionMenu = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        event.stopPropagation();
        setAnchorElWorkspaceAction(event.currentTarget);
    };
    const handleCloseWorkspaceActionMenu = () => {
        setAnchorElWorkspaceAction(null);
    };

    // Delete workspace dialog menu
    const [deleteWorkspaceDialogOpen, setDeleteWorkspaceDialogOpen] =
        useState<boolean>(false);

    // Invite workspace dialog menu
    const [inviteWorkspaceDialogOpen, setInviteWorkspaceDialogOpen] =
        useState<boolean>(false);

    // Edit workspace name
    const [isEditingName, setIsEditingName] = useState(false);

    const generateMenuItems = async () => {
        const openWorkspaceMenuItem: MenuItem = {
            key: 'open-workspace',
            onClick: () => {
                handleCloseWorkspaceActionMenu();
                loadWorkspace();
            },
            label: 'Open',
        };
        const inviteToWorkspaceMenuItem: MenuItem = {
            key: 'invite-to-workpace',
            onClick: () => {
                setInviteWorkspaceDialogOpen(true);
                handleCloseWorkspaceActionMenu();
            },
            label: 'Invite to workspace...',
        };
        const renameWorkspaceMenuItem: MenuItem = {
            key: 'rename-workspace',
            onClick: () => {
                handleCloseWorkspaceActionMenu();
                setIsEditingName(true);
            },
            label: 'Rename',
        };
        const deleteMenuItem: MenuItem = {
            key: 'delete-workspace',
            onClick: () => {
                setDeleteWorkspaceDialogOpen(true);
                handleCloseWorkspaceActionMenu();
            },
            label: 'Delete',
        };

        const generatedMenuItems: MenuItem[] = [];

        if (sharedWithUids.includes(user.uid)) {
            generatedMenuItems.push(openWorkspaceMenuItem);
            generatedMenuItems.push(renameWorkspaceMenuItem);
        }

        if (await isWorkspaceOwner(id, user.uid)) {
            generatedMenuItems.push(openWorkspaceMenuItem);
            generatedMenuItems.push(inviteToWorkspaceMenuItem);
            generatedMenuItems.push(renameWorkspaceMenuItem);
            generatedMenuItems.push(deleteMenuItem);
        }

        setMenuItems(generatedMenuItems);
    };

    if (lastEditedBy && lastEditedTime) {
        return (
            <Card
                variant="outlined"
                sx={{
                    borderWidth: '2px',
                    borderRadius: '8px',
                    position: 'relative',
                }}
                data-testid="workspaces-workspace"
            >
                <CardActionArea
                    component={Box}
                    onDoubleClick={() => {
                        loadWorkspace();
                    }}
                >
                    <CardMedia
                        sx={{ height: 130 }}
                        image="/static/images/cards/contemplative-reptile.jpg"
                        title="green iguana"
                    />
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                flex: '1 0',
                                maxWidth: 'calc(100% - 58px)',
                            }}
                        >
                            <EditableWorkspaceName
                                id={id}
                                name={name}
                                isEditing={isEditingName}
                                setIsEditing={setIsEditingName}
                                user={user}
                                noWrap
                                sx={{
                                    pt: '4px',
                                    pb: '4px',
                                    borderBottom: `2px solid transparent`,
                                }}
                            />
                            <Typography color="text.secondary">
                                Edited {lastEditedTime} by {lastEditedBy}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: '50px',
                            }}
                        >
                            <IconButton
                                aria-label="workspace-actions"
                                aria-controls={
                                    Boolean(anchorElWorkspaceAction)
                                        ? 'workspace-actions-menu'
                                        : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                    Boolean(anchorElWorkspaceAction)
                                        ? 'true'
                                        : undefined
                                }
                                onClick={handleOpenWorkspaceActionMenu}
                                size="large"
                            >
                                <input hidden accept="image/*" type="file" />
                                <MoreHorizIcon />
                            </IconButton>
                        </Box>
                    </CardContent>
                </CardActionArea>
                <Menu
                    sx={{
                        mt: '45px',
                    }}
                    keepMounted
                    anchorEl={anchorElWorkspaceAction}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElWorkspaceAction)}
                    onClose={handleCloseWorkspaceActionMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {menuItems.map((item) => {
                        return (
                            <MenuItem key={item.key} onClick={item.onClick}>
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
                <DeleteWorkspaceDialog
                    open={deleteWorkspaceDialogOpen}
                    close={() => setDeleteWorkspaceDialogOpen(false)}
                    id={id}
                    workspace={workspace}
                />
                <InviteWorkspaceDialog
                    open={inviteWorkspaceDialogOpen}
                    close={() => setInviteWorkspaceDialogOpen(false)}
                    id={id}
                    workspaceDetail={workspaceDetail}
                />
            </Card>
        );
    }
    return <></>;
};

export default WorkspaceCard;
