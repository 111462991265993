import { Theme } from '@mui/material';
import Color from 'color';
import { NodeTokenValue } from '@prbx/types/Nodes';

export const generateShapeColor = (theme: Theme, token?: NodeTokenValue) => {
    let shapeColor = Color(
        theme.palette.getContrastText(theme.palette.background.default)
    )
        .alpha(100)
        .hex();

    if (token && token.unit == 'color') {
        shapeColor = Color(token.value).hex();
    }

    return shapeColor;
};

export const getContrastBorder = (color: string) =>
    Color(color).lightness(50).desaturate(0.8).hex();
