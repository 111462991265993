export const muiButton = {
    styleOverrides: {
        root: () => ({
            borderRadius: '32px',
            paddingLeft: '24px',
            paddingRight: '24px',

            '&.MuiButton-outlined': {
                borderWidth: '2px !important',
            },
        }),
    },
    defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
    },
};
