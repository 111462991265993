import React from 'react';
import { Box, capitalize, Typography } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';

import {
    ComponentNodeType,
    CoreNodeType,
    FlowNode,
    getNodeTypeText,
    NodeType,
    SemanticNodeType,
} from '@prbx/types/Nodes';
import CoreNodeEditor from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/CoreNodeEditor';
import ComponentNodeEditor from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/ComponentNodeEditor';
import SemanticNodeEditor from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/SemanticNodeEditor';

type NodeEditorProps = {
    selectedNode: FlowNode;
};

const NodeEditor = ({ selectedNode }: NodeEditorProps) => {
    const type = selectedNode.type as NodeType;
    const prefixType =
        type === 'core-token' ||
        type === 'semantic-token' ||
        type === 'component-token';

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <CategoryIcon />
                <Typography ml={1} variant="h6">
                    {capitalize(getNodeTypeText(type as NodeType))}{' '}
                    {prefixType && 'token'}
                </Typography>
            </Box>
            {type === 'core-token' && (
                <CoreNodeEditor node={selectedNode as CoreNodeType} />
            )}
            {(type === 'semantic-token' || type === 'component-token') && (
                <SemanticNodeEditor node={selectedNode as SemanticNodeType} />
            )}
            {type === 'component' && (
                <ComponentNodeEditor node={selectedNode as ComponentNodeType} />
            )}
        </>
    );
};

export default NodeEditor;
