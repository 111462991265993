import React, { useEffect, useState } from 'react';
import { Route, useNavigate, useLocation, Routes } from 'react-router-dom';
import ThemingProvider from '@prbx/components/ThemingProvider';
import Home from '@prbx/components/Home';
import { useAuthState } from 'react-firebase-hooks/auth';
import { mAuth } from '@prbx/firebase/firebase';
import { ROUTES } from '@prbx/consts';
import { isUserVerified } from '@prbx/firebase/auth';
import Editor from '@prbx/components/Editor/Editor';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

const App = () => {
    // Init dayjs
    dayjs.extend(relativeTime);

    // Navigation
    const navigate = useNavigate();
    const location = useLocation();

    // Authentication and verification
    const [userVerified, setUserVerified] = useState<boolean>(false);
    const [user, userLoading] = useAuthState(mAuth);
    const handleUserVerification = async () => {
        if (user) {
            const userVerifiedTemp = await isUserVerified(user);
            setUserVerified(userVerifiedTemp);
            if (
                !userVerifiedTemp &&
                location.pathname !== ROUTES.VERIFY_EMAIL
            ) {
                navigate(ROUTES.VERIFY_EMAIL);
            }
        }
    };
    useEffect(() => {
        handleUserVerification();
    }, [user, location]);

    return (
        <ThemingProvider>
            <Box
                sx={{
                    height: '100vh',
                }}
            >
                <Routes>
                    {user && userVerified && !userLoading && (
                        <Route
                            path={'/workspace/:id'}
                            element={<Editor user={user} />}
                        />
                    )}
                    <Route
                        path="*"
                        element={<Home userVerified={userVerified} />}
                    />
                </Routes>
            </Box>
        </ThemingProvider>
    );
};

export default App;
