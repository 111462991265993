import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import { deleteWorkspace, Workspace } from '@prbx/firebase/workspace';

type DeleteWorkspaceDialogProps = {
    open: boolean;
    close: () => void;
    id: string;
    workspace: Workspace;
};

const DeleteWorkspaceDialog = ({
    open,
    close,
    id,
    workspace,
}: DeleteWorkspaceDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="confirm-delete-workspace"
        >
            <DialogTitle>Delete workspace?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you wish to permenantly delete &apos;
                    {workspace.name}
                    &apos;?
                    <br />
                    <b>This process is irreversible.</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <Button
                    color="error"
                    onClick={() => {
                        deleteWorkspace(id);
                        close();
                    }}
                    data-testid="deletworkspacedialog-delete-workspace"
                >
                    Delete workspace
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteWorkspaceDialog;
