import React, { useContext, useState } from 'react';
import { Card, Button, Tooltip } from '@mui/material';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { User } from 'firebase/auth';

import InviteWorkspaceDialog from '@prbx/components/Dashboard/InviteWorkspaceDialog/InviteWorkspaceDialog';
import ConnectedUserList from '@prbx/components/Editor/ConnectedUserManager/ConnectedUserList';
import EditorAccount from '@prbx/components/Editor/EditorAccount';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';

type RightPanelProps = {
    id: string;
    user: User;
};

const RightPanel = ({ id, user }: RightPanelProps) => {
    const workspaceDetail = useContext(WorkspaceContext);
    const { sharedWith } = workspaceDetail;
    const length = Object.keys(sharedWith ?? {}).length;

    // Invite workspace dialog menu
    const [inviteWorkspaceDialogOpen, setInviteWorkspaceDialogOpen] =
        useState<boolean>(false);

    const getShareIcon = () => {
        if (length > 0) {
            return <PeopleRoundedIcon />;
        } else {
            return <LockRoundedIcon />;
        }
    };

    const getShareTooltipTitle = () => {
        if (length > 0) {
            return `Shared with ${length} ${length > 1 ? 'people' : 'person'}`;
        } else {
            return 'Private only to you';
        }
    };

    const isWorkspaceOwner = user.uid == workspaceDetail.createdBy;

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    minHeight: '64px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: '0px 16px',
                    borderRadius: '64px',
                    borderWidth: '2px',
                }}
            >
                <ConnectedUserList id={id} user={user} />
                {isWorkspaceOwner && (
                    <Tooltip title={getShareTooltipTitle()}>
                        <Button
                            onClick={() => setInviteWorkspaceDialogOpen(true)}
                            startIcon={getShareIcon()}
                            variant="contained"
                            sx={{ mr: 2 }}
                            data-testid="rightpanel-share-button"
                        >
                            Share
                        </Button>
                    </Tooltip>
                )}
                <EditorAccount user={user} />
            </Card>
            <InviteWorkspaceDialog
                open={inviteWorkspaceDialogOpen}
                close={() => setInviteWorkspaceDialogOpen(false)}
                id={id}
                workspaceDetail={workspaceDetail}
            />
        </>
    );
};

export default RightPanel;
