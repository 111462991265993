import { db } from '@prbx/firebase/firebase';
import { FlowNode } from '@prbx/types/Nodes';
import { User } from 'firebase/auth';
import { get, ref, remove, serverTimestamp, set } from 'firebase/database';
import { Edge } from 'reactflow';

export type Workspace = {
    name: string;
    createdBy: User['uid'];
    sharedWith: User['uid'][];
    lastEdited: {
        by: User['uid'];
        time: number;
    };
    prefix: string;
    nodes: FlowNode[];
    edges: Edge[];
};

export type WorkspaceDetail = Omit<Workspace, 'nodes' | 'edges'>;

// Update lastEdited
export const updateWorkspace = async (user: User, id: string) => {
    set(ref(db, `/workspaces/${id}/lastEdited`), {
        by: user.uid,
        time: serverTimestamp(),
    });
};

// Deletes a workspace
export const deleteWorkspace = async (id: string) => {
    return remove(ref(db, `/workspaces/${id}`));
};

// Changes the name of a workspace
export const changeWorkspaceName = async (
    user: User,
    id: string,
    newName: string
) => {
    updateWorkspace(user, id);
    return set(ref(db, `/workspaces/${id}/name`), newName);
};

// Changes the prefix of a workspace
export const changeWorkspacePrefix = async (
    user: User,
    id: string,
    newPrefix: string
) => {
    updateWorkspace(user, id);
    return set(ref(db, `/workspaces/${id}/prefix`), newPrefix);
};

// Invites a user to a workspace
// TODO - maybe true or false can be if they have accepted the invitation to the workspace?
export const inviteUserToWorkspace = async (id: string, uid: string) => {
    return set(ref(db, `/workspaces/${id}/sharedWith/${uid}`), true);
};

// Removes a user from a workspace
// TODO - maybe true or false can be if they have accepted the invitation to the workspace?
export const removeUserFromWorkspace = async (id: string, uid: string) => {
    disconnectUserFromWorkspace(id, uid);
    return remove(ref(db, `/workspaces/${id}/sharedWith/${uid}`));
};

// Get all users that workspace is shared to
export const getSharedWithUids = (sharedWith: string[] | undefined) => {
    if (typeof sharedWith === 'undefined') {
        return [];
    } else {
        return Object.keys(sharedWith);
    }
};

// Connect user to database
export const connectUserToWorkspace = (id: string, uid: string) => {
    set(ref(db, `/users/${uid}/connectedWorkspaces/${id}`), true);
    return set(ref(db, `/workspaces/${id}/connectedUsers/${uid}`), true);
};

// Disconnect user from database
export const disconnectUserFromWorkspace = (id: string, uid: string) => {
    remove(ref(db, `/users/${uid}/connectedWorkspaces/${id}`));
    return remove(ref(db, `/workspaces/${id}/connectedUsers/${uid}`));
};

// Check if user is owner of workspace
export const isWorkspaceOwner = async (id: string, uid: string) => {
    const createdBy = await get(ref(db, `/workspaces/${id}/createdBy`));
    return createdBy.val() == uid;
};
