import React, { useEffect, useState } from 'react';
import { Avatar, Tooltip, Typography } from '@mui/material';
import { getDisplayName } from '@prbx/firebase/auth';
import uniqolor from 'uniqolor';

type ConnectedUserProps = {
    uid: string;
};

const ConnectedUser = ({ uid }: ConnectedUserProps) => {
    const [displayName, setDisplayName] = useState<string>();

    useEffect(() => {
        const getUserDetail = async () => {
            setDisplayName(await getDisplayName(uid));
        };

        getUserDetail();
    });

    const userColor = uniqolor(uid);

    if (!displayName) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Tooltip title={displayName}>
            <Avatar
                sx={{ bgcolor: `${userColor.color}`, mr: 1 }}
                alt={displayName}
                src="/static/images/avatar/2.jpg"
                data-testid="connecteduser-user"
            />
        </Tooltip>
    );
};

export default ConnectedUser;
