import React from 'react';
import { Tooltip, IconButton, Avatar, Box, Typography } from '@mui/material';
import { User } from 'firebase/auth';
import { useState } from 'react';
import AccountMenu from '@prbx/components/Navigation/AccountMenu';

type EditorAccountProps = {
    user: User;
};

const EditorAccount = ({ user }: EditorAccountProps) => {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    if (!user) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <>
            <Tooltip title={user.displayName} placement="bottom-end">
                <Box
                    onClick={handleOpenUserMenu}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <IconButton sx={{ p: 0 }}>
                        <Avatar
                            alt={user.displayName ?? 'user account'}
                            src="/static/images/avatar/2.jpg"
                        />
                    </IconButton>
                </Box>
            </Tooltip>
            <AccountMenu
                anchor={anchorElUser}
                closeMenu={handleCloseUserMenu}
                user={user}
            />
        </>
    );
};

export default EditorAccount;
