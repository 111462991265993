import { Box, Tooltip } from '@mui/material';
import React, { MouseEvent, useState } from 'react';
import Draggable, { DraggableEvent } from 'react-draggable';

import { NodeType } from '@prbx/types/Nodes';
import NodeIcon from '@prbx/components/Nodes/NodeIcon';

type AddNodeDraggableProps = {
    type: NodeType;
    onDrop: (x: number, y: number, type: NodeType) => void;
};

const AddNodeDraggable = ({ type, onDrop }: AddNodeDraggableProps) => {
    const nodeRef = React.useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [dragPosition, setDragPosition] = useState<{
        x: number;
        y: number;
    }>({ x: 0, y: 0 });

    const onStart = () => {
        setTooltipOpen(false);
        setDragging(true);
    };
    const onStop = (event: DraggableEvent) => {
        const e = event as MouseEvent;
        onDrop(e.clientX, e.clientY, type);
        setDragPosition({ x: 0, y: 0 });
        setDragging(false);
    };

    const handleOpen = () => setTooltipOpen(!dragging);
    const handleClose = () => setTooltipOpen(false);

    return (
        <Draggable
            nodeRef={nodeRef}
            onStart={onStart}
            onStop={onStop}
            position={dragPosition}
        >
            <span
                ref={nodeRef}
                data-testid={`addnodedraggable-draggable-${type}`}
            >
                <Tooltip
                    title={`Drag to create ${type.replace('-', ' ')}`}
                    open={tooltipOpen}
                    onClose={handleClose}
                    onOpen={handleOpen}
                >
                    <Box
                        sx={{
                            cursor: 'grab',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <NodeIcon
                            type={type}
                            sx={{
                                height: 48,
                                width: 48,
                            }}
                        />
                    </Box>
                </Tooltip>
            </span>
        </Draggable>
    );
};

export default AddNodeDraggable;
