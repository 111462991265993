import React from 'react';
import { useContext } from 'react';
import { Chip } from '@mui/material';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import { FlowNode, NodeType } from '@prbx/types/Nodes';
import NodeIcon from '@prbx/components/Nodes/NodeIcon';
import getFormattedNodeName from '@prbx/utils/getFormattedNodeName';

type NodeChipProps = {
    node: FlowNode;
};

const NodeChip = ({ node }: NodeChipProps) => {
    const { prefix } = useContext(WorkspaceContext);
    const { type, data } = node;

    return (
        <>
            <Chip
                sx={{
                    '& .MuiSvgIcon-root': {
                        ml: '6px',
                    },
                }}
                icon={<NodeIcon type={type as NodeType} />}
                label={getFormattedNodeName(
                    prefix,
                    type as NodeType,
                    data.name
                )}
                variant="outlined"
            />
        </>
    );
};

export default NodeChip;
