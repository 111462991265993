import React, { SVGProps, useMemo } from 'react';
import { useTheme } from '@mui/material';
import Color from 'color';

import { generateShapeColor, getContrastBorder } from '@prbx/utils/color';
import { NodeTokenValue } from '@prbx/types/Nodes';

type ComponentTokenShapeProps = {
    overrideShapeColor?: string;
    token?: NodeTokenValue;
    svgProps?: SVGProps<SVGSVGElement>;
    pathProps?: SVGProps<SVGPathElement>;
};

const ComponentTokenShape = ({
    overrideShapeColor,
    token,
    svgProps,
    pathProps,
}: ComponentTokenShapeProps) => {
    const theme = useTheme();

    const shapeColor = useMemo(
        () => overrideShapeColor ?? generateShapeColor(theme, token),
        [overrideShapeColor, theme, token]
    );

    const needsBorder = useMemo(
        () =>
            Color(shapeColor).contrast(
                Color(theme.palette.background.default)
            ) < 1.5,
        [shapeColor, theme.palette.background.default]
    );

    const contrastBorder = useMemo(
        () => getContrastBorder(shapeColor),
        [shapeColor, theme]
    );

    const svgPropsToApply = {
        fill: shapeColor,
        ...svgProps,
        ...(needsBorder && {
            stroke: contrastBorder,
            strokeWidth: '4px',
        }),
    };

    return (
        <svg
            {...svgPropsToApply}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 161.42 161.93"
        >
            <clipPath id="componentIconClip">
                <path
                    {...pathProps}
                    d="m75.85,1.64L3.14,57.19c-2.66,2.04-3.78,5.52-2.79,8.72l27.93,90.39c1.04,3.35,4.13,5.64,7.64,5.64h89.56c3.51,0,6.61-2.29,7.64-5.64l27.93-90.39c.99-3.2-.12-6.68-2.79-8.72L85.57,1.64c-2.87-2.19-6.85-2.19-9.71,0Z"
                />
            </clipPath>
            <path
                {...pathProps}
                clipPath="url(#componentIconClip)"
                d="m75.85,1.64L3.14,57.19c-2.66,2.04-3.78,5.52-2.79,8.72l27.93,90.39c1.04,3.35,4.13,5.64,7.64,5.64h89.56c3.51,0,6.61-2.29,7.64-5.64l27.93-90.39c.99-3.2-.12-6.68-2.79-8.72L85.57,1.64c-2.87-2.19-6.85-2.19-9.71,0Z"
            />
        </svg>
    );
};

export default ComponentTokenShape;
