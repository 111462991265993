import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { muiButton } from '@prbx/theme/options/components/muiButton';
import { muiButttonBase } from '@prbx/theme/options/components/muiButtonBase';
import { muiCssBaseline } from '@prbx/theme/options/components/muiCssBaseline';
import { muiDialog } from '@prbx/theme/options/components/muiDialog';
import { muiMenu } from '@prbx/theme/options/components/muiMenu';
import { muiTextField } from '@prbx/theme/options/components/muiTextField';
import { muiTooltip } from '@prbx/theme/options/components/muiTooltip';
import { getPalette } from '@prbx/theme/options/palette';
import { typography } from '@prbx/theme/options/typography';

export const createPrbxTheme = (mode: PaletteMode) =>
    createTheme({
        ...getPalette(mode),
        typography: typography,
        components: {
            MuiTextField: muiTextField,
            MuiButtonBase: muiButttonBase,
            MuiButton: muiButton,
            MuiTooltip: muiTooltip,
            MuiCssBaseline: muiCssBaseline,
            MuiDialog: muiDialog,
            MuiMenu: muiMenu,
        },
    });
