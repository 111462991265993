import React from 'react';
import { ListItemIcon, ListItemText, MenuItem, useTheme } from '@mui/material';
import { useContext } from 'react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ColorModeContext } from '@prbx/components/ThemingProvider';

const ColorModeToggle = () => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    return (
        <MenuItem
            onClick={colorMode.toggleColorMode}
            data-testid="colormodetoggle-toggle-color-mode"
        >
            <ListItemIcon>
                {theme.palette.mode === 'dark' ? (
                    <Brightness7Icon />
                ) : (
                    <Brightness4Icon />
                )}
            </ListItemIcon>
            <ListItemText>
                Toggle {theme.palette.mode === 'dark' ? 'light' : 'dark'} mode
            </ListItemText>
        </MenuItem>
    );
};

export default ColorModeToggle;
