import { FlowNode } from '@prbx/types/Nodes';
import { Edge, getConnectedEdges } from 'reactflow';

export const getIncomingProperties = (
    node: FlowNode,
    edges: Edge[],
    getNode
): string[] => {
    const incomingEdges = getConnectedEdges([node], edges).filter((edge) => {
        const sourceNode = getNode(edge.source);
        return edge.target === node.id && sourceNode.type !== 'component';
    });
    return incomingEdges.flatMap((edge) => edge.data.properties);
};
