import React, { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { mAuth } from '@prbx/firebase/firebase';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ERROR_SIGN_IN, ROUTES } from '@prbx/consts';
import {
    browserSessionPersistence,
    setPersistence,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FirebaseError } from 'firebase/app';

const SignIn = () => {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(mAuth);
    const [error, setError] = useState<ERROR_SIGN_IN | null>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null);
        const data = new FormData(event.currentTarget);
        let email = '';
        let password = '';
        email = data.get('email') as string;
        password = data.get('password') as string;
        const persistence = data.get('remember') ? true : false;

        if (!persistence) {
            setPersistence(mAuth, browserSessionPersistence);
        }

        signInWithEmailAndPassword(mAuth, email, password).catch(
            (error: FirebaseError) => {
                switch (error.code) {
                    case 'auth/invalid-email':
                    case 'auth/user-not-found':
                    case 'auth/wrong-password': {
                        // ERROR_SIGN_IN.INVALID_EMAIL_PASSWORD;
                        // Entered incorrect email and/or password
                        setError(ERROR_SIGN_IN.INVALID_EMAIL_PASSWORD);
                        return;
                    }
                    case 'auth/too-many-requests': {
                        // ERROR_SIGN_IN.TOO_MANY_REQUESTS
                        // Entered correct email and incorrect password too many times
                        setError(ERROR_SIGN_IN.TOO_MANY_REQUESTS);
                        return;
                    }
                    case 'auth/internal-error': {
                        setError(ERROR_SIGN_IN.INVALID_EMAIL_PASSWORD);
                        return;
                    }
                    default: {
                        alert(error.code);
                    }
                }
            }
        );
    };

    useEffect(() => {
        if (user) navigate(ROUTES.DASHBOARD);
    }, [user]);

    if (loading || user) {
        return <></>;
    }

    return (
        <Box>
            {error == ERROR_SIGN_IN.TOO_MANY_REQUESTS && (
                <Alert sx={{ mb: '64px' }} severity="error">
                    {ERROR_SIGN_IN.TOO_MANY_REQUESTS} To unlock your account,
                    please{' '}
                    <Link
                        component={RouterLink}
                        to={ROUTES.FORGOT_PASSWORD}
                        variant="body2"
                    >
                        reset your password.
                    </Link>
                </Alert>
            )}
            <Stack
                spacing={2}
                sx={{ maxWidth: '400px', margin: 'auto', mt: '32px' }}
                component="form"
                noValidate
                onSubmit={handleSubmit}
            >
                <Typography
                    pb={4}
                    variant="h3"
                    sx={{
                        alignSelf: 'center',
                    }}
                >
                    Sign in
                </Typography>
                <TextField
                    required
                    id="email"
                    name="email"
                    autoComplete="email"
                    label="Email Address"
                    error={error == ERROR_SIGN_IN.INVALID_EMAIL_PASSWORD}
                    data-testid="signin-email"
                />
                <TextField
                    required
                    id="password"
                    name="password"
                    autoComplete="current-password"
                    label="Password"
                    type="password"
                    error={error == ERROR_SIGN_IN.INVALID_EMAIL_PASSWORD}
                    helperText={
                        error == ERROR_SIGN_IN.INVALID_EMAIL_PASSWORD
                            ? ERROR_SIGN_IN.INVALID_EMAIL_PASSWORD
                            : ''
                    }
                    data-testid="signin-password"
                />
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Grid item xs>
                        <FormControlLabel
                            control={<Checkbox value={false} name="remember" />}
                            label="Remember me"
                        />
                    </Grid>
                    <Grid item>
                        <Link
                            component={RouterLink}
                            to={ROUTES.FORGOT_PASSWORD}
                            variant="body2"
                            data-testid="signin-forgot-password"
                        >
                            Forgot password?
                        </Link>
                    </Grid>
                </Grid>

                <Button
                    type="submit"
                    variant="contained"
                    data-testid="signin-sign-in"
                >
                    Sign in
                </Button>
                <Typography
                    sx={{ paddingTop: '16px', textAlign: 'center' }}
                    color="extra.textTertiary"
                >
                    {"Don't have an account?"}{' '}
                    <Link
                        component={RouterLink}
                        to={ROUTES.REGISTER}
                        variant="body2"
                    >
                        Register
                    </Link>
                </Typography>
            </Stack>
        </Box>
    );
};

export default SignIn;
