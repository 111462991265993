import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    FormLabel,
    TextField,
    Stack,
} from '@mui/material';
import ConfirmationButton from '@prbx/components/Dashboard/ConfirmationButton';
import { workspaceNameRegex } from '@prbx/components/Dashboard/EditableWorkspaceName';
import { ERROR_WORKSPACE, GENERAL, ROUTES } from '@prbx/consts';
import {
    deleteWorkspace,
    changeWorkspaceName,
    changeWorkspacePrefix,
    isWorkspaceOwner,
    WorkspaceDetail,
} from '@prbx/firebase/workspace';
import { User } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

type WorkspaceSettingsDialogProps = {
    open: boolean;
    close: () => void;
    id: string;
    workspaceDetail: WorkspaceDetail;
    user: User;
};

const prefixRegex = /^[\w]+$/;

const WorkspaceSettingsDialog = ({
    open,
    close,
    id,
    workspaceDetail,
    user,
}: WorkspaceSettingsDialogProps) => {
    const navigate = useNavigate();
    const [name, setName] = useState<string>(workspaceDetail.name);
    const [prefix, setPrefix] = useState<string>(workspaceDetail.prefix);
    const [workspaceOwner, setWorkspaceOwner] = useState<boolean>(false);

    const handleClose = () => {
        close();
    };

    useEffect(() => {
        setName(workspaceDetail.name);
        setPrefix(workspaceDetail.prefix);
    }, [workspaceDetail]);

    useEffect(() => {
        if (open) {
            setName(workspaceDetail.name);
            setPrefix(workspaceDetail.prefix);
        }
    }, [open]);

    useEffect(() => {
        const setPrivileges = async () => {
            setWorkspaceOwner(await isWorkspaceOwner(id, user.uid));
        };

        setPrivileges();
    }, [user]);

    const nameTooLongError = name.length > 32;
    const nameEmptyError = !workspaceNameRegex.test(name);
    const prefixInvalidError = !prefixRegex.test(prefix);
    const prefixEmptyError = prefix.length == 0;

    const handleNameChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        const nextName = event.target.value.toString();
        setName(nextName);
    };

    const handlePrefixChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        const nextPrefix = event.target.value.toString();
        setPrefix(nextPrefix);
    };

    const isUnsavedChanges =
        name !== workspaceDetail.name || prefix !== workspaceDetail.prefix;

    const canUpdateWorkspace =
        isUnsavedChanges &&
        !nameTooLongError &&
        !nameEmptyError &&
        !prefixInvalidError &&
        !prefixEmptyError;

    const handleUpdateWorkspace = async () => {
        if (name !== workspaceDetail.name) {
            changeWorkspaceName(user, id, name);
        }
        if (prefix !== workspaceDetail.prefix) {
            changeWorkspacePrefix(user, id, prefix);
        }
        toast(GENERAL.WORKSPACE_CHANGES_SAVED);
        close();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="workspace-settings"
        >
            <DialogTitle>Workspace Settings</DialogTitle>
            <DialogContent sx={{ width: 400 }}>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel
                            error={nameTooLongError || nameEmptyError}
                            sx={{ mb: 1 }}
                        >
                            Workspace Name:
                        </FormLabel>
                        <TextField
                            variant="filled"
                            value={name}
                            onChange={handleNameChange}
                            error={
                                isUnsavedChanges &&
                                (nameTooLongError || nameEmptyError)
                            }
                            helperText={
                                (isUnsavedChanges &&
                                    nameTooLongError &&
                                    ERROR_WORKSPACE.WORKSPACE_NAME_TOO_LONG) ||
                                (isUnsavedChanges &&
                                    nameEmptyError &&
                                    ERROR_WORKSPACE.WORKSPACE_NAME_EMPTY)
                            }
                        ></TextField>
                    </FormControl>
                    <FormControl>
                        <FormLabel
                            error={prefixInvalidError || prefixEmptyError}
                            sx={{ mb: 1 }}
                        >
                            Token prefix:
                        </FormLabel>
                        <TextField
                            variant="filled"
                            value={prefix}
                            onChange={handlePrefixChange}
                            error={
                                isUnsavedChanges &&
                                (prefixInvalidError || prefixEmptyError)
                            }
                            helperText={
                                (isUnsavedChanges &&
                                    prefixInvalidError &&
                                    ERROR_WORKSPACE.WORKSPACE_PREFIX_INVALID) ||
                                (isUnsavedChanges &&
                                    prefixEmptyError &&
                                    ERROR_WORKSPACE.WORKSPACE_PREFIX_EMPTY)
                            }
                            data-testid="workspacesettingsdialog-prefix"
                        ></TextField>
                    </FormControl>
                    {workspaceOwner && (
                        <ConfirmationButton
                            onConfirm={() => {
                                deleteWorkspace(id);
                                navigate(ROUTES.DASHBOARD);
                                handleClose();
                            }}
                            actionText="delete the workspace"
                        >
                            Delete workspace
                        </ConfirmationButton>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    disabled={!canUpdateWorkspace}
                    variant="contained"
                    onClick={() => {
                        handleUpdateWorkspace();
                    }}
                    data-testid="workspacesettingsdialog-save"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WorkspaceSettingsDialog;
