import {
    ComponentNodeType,
    CoreNodeType,
    FlowNode,
    NodeData,
    NodeType,
    SemanticNodeType,
} from '@prbx/types/Nodes';
import { getStyleApplications } from '@prbx/utils/styles';
import getFormattedNodeName from '@prbx/utils/getFormattedNodeName';
import {
    Edge,
    getConnectedEdges,
    getIncomers,
    getOutgoers,
    Instance,
} from 'reactflow';
import { getUpstreamCoreToken } from '@prbx/utils/upstreamAttributes';

const generateInheritingTokenDefinition = (
    prefix,
    nodes: FlowNode[],
    edges: Edge[],
    token: SemanticNodeType | ComponentNodeType
) => {
    const upstreamCoreToken = getUpstreamCoreToken(token, nodes, edges);
    if (upstreamCoreToken) {
        const upstreamToken = getIncomers(token, nodes, edges)[0];
        const upstreamTokenName = getFormattedNodeName(
            prefix,
            upstreamToken.type as NodeType,
            upstreamToken.data.name
        );
        return `${getFormattedNodeName(
            prefix,
            token.type as NodeType,
            token.data.name
        )}: ${upstreamTokenName}; \n`;
    } else {
        return '';
    }
};

export const generateTokenDefinitions = (
    prefix: string,
    nodes: FlowNode[],
    edges: Edge[]
) => {
    const coreTokens = nodes.filter(
        (nd) => nd.type === 'core-token'
    ) as CoreNodeType[];
    const semanticTokens = nodes.filter((nd) => nd.type === 'semantic-token');
    const componentTokens = nodes.filter((nd) => nd.type === 'component-token');

    let codeToReturn = ``;
    codeToReturn += coreTokens.reduce((acc, coreToken) => {
        const outgoers = getOutgoers(coreToken, nodes, edges);
        if (outgoers.length !== 0) {
            const coreTokenDefinition = `${getFormattedNodeName(
                prefix,
                coreToken.type as NodeType,
                coreToken.data.name
            )}: ${coreToken.data.token.value};\n`;
            return acc + coreTokenDefinition;
        } else {
            return acc;
        }
    }, ``);

    codeToReturn += semanticTokens.reduce((acc, semanticToken) => {
        return (
            acc +
            generateInheritingTokenDefinition(
                prefix,
                nodes,
                edges,
                semanticToken
            )
        );
    }, ``);

    codeToReturn += componentTokens.reduce((acc, componentToken) => {
        return (
            acc +
            generateInheritingTokenDefinition(
                prefix,
                nodes,
                edges,
                componentToken
            )
        );
    }, ``);

    return codeToReturn;
};

export const generateTokenApplications = (
    prefix: string,
    nodes: FlowNode[],
    edges: Edge[],
    getNode: Instance.GetNode<NodeData>
) => {
    const components = nodes.filter((nd) => nd.type === 'component');

    let codeToReturn = ``;
    codeToReturn += components.reduce((acc, component) => {
        const connectedEdges = getConnectedEdges([component], edges).filter(
            (edge) => edge.target === component.id
        );
        const styleApplications = getStyleApplications(
            prefix,
            connectedEdges,
            getNode,
            nodes,
            edges
        );
        const formatted = `.${component.data.name} {   ${styleApplications}\n};\n\n`;

        if (styleApplications !== '') {
            return acc + formatted;
        } else {
            return acc;
        }
    }, ``);

    return codeToReturn;
};

export const generateTokenJSON = (
    prefix: string,
    nodes: FlowNode[],
    edges: Edge[]
) => {
    const coreTokens = nodes.filter(
        (nd) => nd.type === 'core-token'
    ) as CoreNodeType[];
    const semanticTokens = nodes.filter((nd) => nd.type === 'semantic-token');
    const componentTokens = nodes.filter((nd) => nd.type === 'component-token');

    let JSONToReturn = {};
    JSONToReturn = {
        ...JSONToReturn,
        ...coreTokens.reduce((acc, coreToken) => {
            const outgoers = getOutgoers(coreToken, nodes, edges);
            if (outgoers.length !== 0) {
                const coreTokenDefinition = {
                    [getFormattedNodeName(
                        prefix,
                        coreToken.type as NodeType,
                        coreToken.data.name
                    )]: {
                        '$value': `${coreToken.data.token.value}`,
                    },
                };
                return Object.assign(acc, coreTokenDefinition);
            } else {
                return acc;
            }
        }, {}),
    };

    JSONToReturn = {
        ...JSONToReturn,
        ...semanticTokens.reduce((acc, semanticToken) => {
            const upstreamCoreToken = getUpstreamCoreToken(
                semanticToken,
                nodes,
                edges
            );
            if (upstreamCoreToken) {
                const upstreamToken = getIncomers(
                    semanticToken,
                    nodes,
                    edges
                )[0];
                const upstreamTokenName = getFormattedNodeName(
                    prefix,
                    upstreamToken.type as NodeType,
                    upstreamToken.data.name
                );
                const semanticTokenDefinition = {
                    [getFormattedNodeName(
                        prefix,
                        semanticToken.type as NodeType,
                        semanticToken.data.name
                    )]: {
                        '$value': `${upstreamTokenName}`,
                    },
                };
                return Object.assign(acc, semanticTokenDefinition);
            } else {
                return acc;
            }
        }, {}),
    };

    JSONToReturn = {
        ...JSONToReturn,
        ...componentTokens.reduce((acc, componentToken) => {
            const upstreamCoreToken = getUpstreamCoreToken(
                componentToken,
                nodes,
                edges
            );
            if (upstreamCoreToken) {
                const upstreamToken = getIncomers(
                    componentToken,
                    nodes,
                    edges
                )[0];
                const upstreamTokenName = getFormattedNodeName(
                    prefix,
                    upstreamToken.type as NodeType,
                    upstreamToken.data.name
                );
                const componentTokenDefinition = {
                    [getFormattedNodeName(
                        prefix,
                        componentToken.type as NodeType,
                        componentToken.data.name
                    )]: {
                        '$value': `${upstreamTokenName}`,
                    },
                };
                return Object.assign(acc, componentTokenDefinition);
            } else {
                return acc;
            }
        }, {}),
    };

    return JSONToReturn;
};
