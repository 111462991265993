import React, { useContext, useEffect, useMemo } from 'react';
import { TextField, InputAdornment, Typography } from '@mui/material';
import { FlowNode, getNodeTypeText, NodeType } from '@prbx/types/Nodes';
import { ERROR_UPDATE_TOKEN } from '@prbx/consts';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import { isNodeNameInUse, isValidNodeName } from '@prbx/utils/node';

type NodeNameTextFieldProps = {
    enablePrefix?: boolean;
    node: FlowNode;
    state: string;
    error: boolean;
    setState: React.Dispatch<React.SetStateAction<string>>;
    setErrorState: React.Dispatch<React.SetStateAction<boolean>>;
};

const NodeNameTextField = ({
    enablePrefix,
    node,
    state,
    error,
    setState,
    setErrorState,
}: NodeNameTextFieldProps) => {
    const { prefix } = useContext(WorkspaceContext);
    const { sNodes: nodes } = useContext(FlowContext);
    const nameInvalidError = useMemo(() => !isValidNodeName(state), [state]);
    const nameInUseError = useMemo(
        () => isNodeNameInUse(nodes as FlowNode[], node, state),
        [nodes, node, state]
    );

    useEffect(() => {
        setErrorState(nameInvalidError || nameInUseError);
    }, [nameInvalidError, nameInUseError]);

    const handleNameChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        const nextName = event.target.value.toString();
        setState(nextName);
    };

    return (
        <TextField
            fullWidth
            variant="filled"
            value={state}
            error={error}
            helperText={
                error && nameInvalidError
                    ? ERROR_UPDATE_TOKEN.INVALID_NAME
                    : error && nameInUseError
                    ? ERROR_UPDATE_TOKEN.NAME_IN_USE
                    : ''
            }
            onChange={handleNameChange}
            InputProps={{
                ...(enablePrefix && {
                    startAdornment: (
                        <InputAdornment
                            sx={{ maxWidth: '50%' }}
                            position="start"
                        >
                            <Typography noWrap>
                                --{prefix}-
                                {getNodeTypeText(node.type as NodeType)}-
                            </Typography>
                        </InputAdornment>
                    ),
                }),
            }}
            InputLabelProps={{ disabled: true }}
            data-testid="nodenametextfield-textfield"
        />
    );
};

export default NodeNameTextField;
