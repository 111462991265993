import { db, mAuth } from '@prbx/firebase/firebase';
import { disconnectUserFromWorkspace } from '@prbx/firebase/workspace';
import {
    User,
    sendEmailVerification,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';
import {
    set,
    ref,
    serverTimestamp,
    get,
    query,
    orderByValue,
    orderByKey,
} from 'firebase/database';

// Send verification email
export const sendVerificationEmail = (user: User) => {
    // TODO maybe add continue url?
    // const actionCodeSettings = {
    //     url: 'https://prbx-firebase.web.app/verify-email',
    // };
    sendEmailVerification(user);
    set(ref(db, `/users/${user.uid}/lastEmailVerifySent`), serverTimestamp());
};

// Check if username exists already
export const isDisplayNameInUse = async (displayName: string) => {
    const displayNames = await get(
        query(ref(db, '/displayNames'), orderByValue())
    ).then((snapshot) => snapshot.val());
    return displayNames
        ? Object.values(displayNames).some(
              (el) => (el as string).toLowerCase() == displayName.toLowerCase()
          )
        : false;
};

// Get the displayname from a uid
export const getDisplayName = async (uid: string) => {
    const displayNames = await get(
        query(ref(db, '/displayNames'), orderByValue())
    ).then((snapshot) => snapshot.val());
    return displayNames[uid] as string;
};

// Get the uid from a displayname
export const getUid = async (displayName: string) => {
    const uids = await get(query(ref(db, '/displayNames'), orderByKey())).then(
        (snapshot) => snapshot.val()
    );

    return Object.keys(uids).find(
        (key) => uids[key].toLowerCase() === displayName.toLowerCase()
    );
};

// Check if user is verified in the database
export const isUserVerified = async (user: User) => {
    const verified = await (
        await get(ref(db, `/users/${user.uid}/emailVerified/`))
    ).val();

    if (user.emailVerified && !verified) {
        set(ref(db, `/users/${user.uid}/emailVerified/`), true);
        return true;
    }

    return verified;
};

// Send password reset
export const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(mAuth, email);
    } catch (e) {
        // TODO handle error here? Also maybe a promise back to the original UI to showcase an internal server error? Make sure we don't alert the user if the email is incorrect
        // const err = e instanceof FirebaseError;
        // if (err) {
        //     alert(e.code);
        // }
    }
};

// Sign out
export const signOutUser = async (user: User) => {
    const { uid } = user;

    await get(ref(db, `/users/${uid}/connectedWorkspaces`))
        .then((result) => {
            Object.keys(result.val()).forEach((id) => {
                disconnectUserFromWorkspace(id, uid);
            });
        })
        .catch(() => {
            // TODO handle error
        });

    return signOut(mAuth);
};
