export const muiCssBaseline = {
    styleOverrides: {
        body: {
            scrollbarColor: '#E0E0E0',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                width: 12,
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                // borderRadius: 8,
                // backgroundColor: '#E0E0E0',
                // border: '6px solid rgba(0, 0, 0, 0)',
                backgroundClip: 'padding-box',
                borderRadius: '9999px',
                backgroundColor: '#E0E0E0',
            },

            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
                {
                    backgroundColor: '#959595',
                },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
                {
                    backgroundColor: '#959595',
                },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
                {
                    backgroundColor: '#959595',
                },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                backgroundColor: '#2b2b2b',
            },
        },
    },
};
