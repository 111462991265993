import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Avatar,
    Typography,
    useTheme,
    Tooltip,
    Button,
} from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { mAuth } from '@prbx/firebase/firebase';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '@prbx/consts';
import AccountMenu from '@prbx/components/Navigation/AccountMenu';

const NavigationAccount = () => {
    const theme = useTheme();
    const [user, loading] = useAuthState(mAuth);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>
            {!loading && (
                <>
                    <Box
                        sx={{
                            pointerEvents: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {user ? (
                            <>
                                <Tooltip
                                    title={user.email}
                                    placement="bottom-end"
                                >
                                    <Box
                                        onClick={handleOpenUserMenu}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                        data-testid="navigationaccount-user-account"
                                    >
                                        <Typography
                                            mr={2}
                                            variant="h6"
                                            sx={{ textAlign: 'right' }}
                                        >
                                            {user.displayName}
                                        </Typography>

                                        <IconButton sx={{ p: 0 }}>
                                            <Avatar
                                                alt={
                                                    user.displayName ??
                                                    'user account'
                                                }
                                                src="/static/images/avatar/2.jpg"
                                            />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                                <AccountMenu
                                    anchor={anchorElUser}
                                    closeMenu={handleCloseUserMenu}
                                    user={user}
                                />
                            </>
                        ) : (
                            <>
                                <Typography
                                    variant="button"
                                    component={RouterLink}
                                    to={ROUTES.SIGN_IN}
                                    sx={{
                                        mr: 2,
                                        alignSelf: 'center',
                                        textDecoration: 'none',
                                        color: theme.palette.text.primary,
                                    }}
                                    data-testid="navigationaccount-sign-in"
                                >
                                    Sign in
                                </Typography>
                                <Button
                                    to={user == null ? ROUTES.REGISTER : ''}
                                    component={RouterLink}
                                    variant="contained"
                                    sx={{ ml: 1 }}
                                    data-testid="navigationaccount-get-started"
                                >
                                    Get started
                                </Button>
                            </>
                        )}
                    </Box>
                </>
            )}
        </>
    );
};

export default NavigationAccount;
