import { db } from '@prbx/firebase/firebase';
import { updateWorkspace } from '@prbx/firebase/workspace';
import { FlowNode } from '@prbx/types/Nodes';
import { debounce } from '@prbx/utils/debounce';
import { User } from 'firebase/auth';
import { set, ref } from 'firebase/database';
import { Edge } from 'reactflow';
import { isEqual } from 'lodash';

// Removes selected property from nodes
const cleanNodes = (nodes: FlowNode[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return nodes.map(({ selected, ...rest }) => rest);
};

// Removes selected property from edges
const cleanEdges = (edges: Edge[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return edges.map(({ selected, ...rest }) => rest);
};

// Update list of nodes in a workspace
const updateNodes = (
    user: User,
    id: string,
    data: { nodes: FlowNode[]; version: number }
) => {
    updateWorkspace(user, id);
    set(ref(db, `/workspaces/${id}/nodes`), data);
};

// Update list of edges in a workspace
const updateEdges = (
    user: User,
    id: string,
    data: { edges: Edge[]; version: number }
) => {
    updateWorkspace(user, id);
    set(ref(db, `/workspaces/${id}/edges`), data);
};

export const TESTING_cleanNodes = cleanNodes;
export const TESTING_cleanEdges = cleanEdges;
export const TESTING_updateNodes = updateNodes;
export const TESTING_updateEdges = updateEdges;

// Synchronise nodes from client to server
const syncNodesWithServer = (
    id: string,
    user: User,
    clientData: { nodes: FlowNode[]; version: number },
    serverData: { nodes: FlowNode[]; version: number },
    setNodesVersion: React.Dispatch<React.SetStateAction<number>>
) => {
    const clientNewVersion = clientData.version + 1;
    if (clientNewVersion > serverData.version) {
        const cleanedClientNodes = cleanNodes(clientData.nodes ?? []);
        const cleanedServerNodes = cleanNodes(serverData.nodes ?? []);
        if (!isEqual(cleanedServerNodes, cleanedClientNodes)) {
            updateNodes(user, id, {
                nodes: cleanedClientNodes,
                version: clientNewVersion,
            });
            setNodesVersion(clientNewVersion);
        }
    }
};

// Synchronise edges from client to server
const syncEdgesWithServer = (
    id: string,
    user: User,
    clientData: { edges: Edge[]; version: number },
    serverData: { edges: Edge[]; version: number },
    setEdgesVersion: React.Dispatch<React.SetStateAction<number>>
) => {
    const clientNewVersion = clientData.version + 1;
    if (clientNewVersion > serverData.version) {
        const cleanedClientEdges = cleanEdges(clientData.edges ?? []);
        const cleanedServerEdges = cleanEdges(serverData.edges ?? []);
        if (!isEqual(cleanedServerEdges, cleanedClientEdges)) {
            updateEdges(user, id, {
                edges: cleanedClientEdges,
                version: clientNewVersion,
            });
            setEdgesVersion(clientNewVersion);
        }
    }
};

// Synchronise nodes from server to client
const syncNodesWithClient = (
    clientData: { nodes: FlowNode[]; version: number },
    serverData: { nodes: FlowNode[]; version: number },
    setNodes: React.Dispatch<React.SetStateAction<FlowNode[]>>,
    setNodesVersion: React.Dispatch<React.SetStateAction<number>>
) => {
    if (serverData.version > clientData.version) {
        const cleanedClientNodes = cleanNodes(clientData.nodes ?? []);
        const cleanedServerNodes = cleanNodes(serverData.nodes ?? []);
        if (!isEqual(cleanedServerNodes, cleanedClientNodes)) {
            // setNodes(cleanedServerNodes);

            setNodes((prevNodes) =>
                cleanedServerNodes.map((newNode) => {
                    const prevItem = prevNodes.find(
                        (item) => item.id === newNode.id
                    );
                    return {
                        ...newNode,
                        selected: prevItem ? prevItem.selected : false,
                    };
                })
            );

            setNodesVersion(serverData.version);
        }
    }
};

// Synchronise edges from server to client
const syncEdgesWithClient = (
    clientData: { edges: Edge[]; version: number },
    serverData: { edges: Edge[]; version: number },
    setEdges: React.Dispatch<React.SetStateAction<Edge[]>>,
    setEdgesVersion: React.Dispatch<React.SetStateAction<number>>
) => {
    if (serverData.version > clientData.version) {
        const cleanedClientEdges = cleanEdges(clientData.edges ?? []);
        const cleanedServerEdges = cleanEdges(serverData.edges ?? []);
        if (!isEqual(cleanedServerEdges, cleanedClientEdges)) {
            // setEdges(cleanedServerEdges);

            setEdges((prevEdges) =>
                cleanedServerEdges.map((newEdge) => {
                    const prevItem = prevEdges.find(
                        (item) => item.id === newEdge.id
                    );
                    return {
                        ...newEdge,
                        selected: prevItem ? prevItem.selected : false,
                    };
                })
            );

            setEdgesVersion(serverData.version);
        }
    }
};

export const TESTING_syncNodesWithServer = syncNodesWithServer;
export const TESTING_syncEdgesWithServer = syncEdgesWithServer;
export const TESTING_syncNodesWithClient = syncNodesWithClient;
export const TESTING_syncEdgesWithClient = syncEdgesWithClient;

export const debouncedSyncNodesWithServer = debounce(syncNodesWithServer, 300);
export const debouncedSyncEdgesWithServer = debounce(syncEdgesWithServer, 300);
export const debouncedSyncNodesWithClient = debounce(syncNodesWithClient, 300);
export const debouncedSyncEdgesWithClient = debounce(syncEdgesWithClient, 300);
