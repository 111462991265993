export const APP_NAME = 'PRBX';

export const APP_DOMAIN =
    process.env.NODE_ENV === 'production'
        ? 'prbx.commitchell.com'
        : 'localhost:3000';

export const ROUTES = {
    DASHBOARD: '/',
    SIGN_IN: '/sign-in',
    REGISTER: '/sign-up',
    FORGOT_PASSWORD: '/forgot-password',
    VERIFY_EMAIL: '/verify-email',
    TERMS_OF_USE: '/terms-of-use',
    PRIVACY_POLICY: '/privacy-policy',
    WORKSPACE: '/workspace/',
};

export enum ERROR_SIGN_IN {
    INVALID_EMAIL_PASSWORD = 'The email or password entered is invalid.',
    TOO_MANY_REQUESTS = 'You have made too many requests. Please wait a minute before trying again.',
}

export enum ERROR_REGISTER {
    TERMS_NOT_ACCEPTED = 'Please accept the terms of use and privacy policy before continuing.',
    INVALID_EMAIL = 'Please enter a valid email address.',
    INVALID_DISPLAYNAME = 'Please enter a valid username. It must be at least 6 characters, and only contain letters (a-z).',
    WEAK_PASSWORD = 'The password you have entered is too weak. It must be at least 6 characters.',
    PASSWORDS_NOT_MATCH = 'The passwords you have entered do not match.',
    EMAIL_IN_USE = 'The email entered is already in use.',
    DISPLAYNAME_IN_USE = 'The username entered is already in use.',
}

export enum ERROR_INVITE_USER {
    USER_ALREADY_INVITED = 'You have already invited this user.',
    USER_NOT_EXIST = 'This user does not exist.',
    CANNOT_INVITE_CREATOR = 'You cannot invite yourself.',
}

export enum ERROR_WORKSPACE {
    WORKSPACE_NAME_TOO_LONG = 'Workspace name is too long. A workspace name can be up to 32 characters in length.',
    WORKSPACE_NAME_EMPTY = 'Please enter a workspace name.',
    CHANGED_WORKSPACE_NAME = 'Changed workspace name.',
    WORKSPACE_PREFIX_EMPTY = 'Please enter a prefix.',
    WORKSPACE_PREFIX_INVALID = 'Please enter a valid prefix. A prefix must only contain alphanumeric characters and underscores.',
}

export enum GENERAL {
    WORKSPACE_CHANGES_SAVED = 'Workspace changes saved',
}

export enum ERROR_UPDATE_TOKEN {
    INVALID_NAME = 'Token name is invalid.',
    NAME_IN_USE = 'Token name is in use.',
    INVALID_TOKEN_VALUE = 'Not a valid property.',
}

export enum ERROR_UPDATE_COMPONENT {
    INVALID_NAME = 'Component name is invalid.',
    NAME_IN_USE = 'Component name is in use.',
    INVALID_COMPONENT_TAG = 'Not a valid HTML tag.',
    INVALID_CANVAS_COLOR = 'Not a valid canvas color.',
    INVALID_CHILD_TEXT = 'Component child text cannot be empty.',
}

export enum ERROR_CREATE_PROPERTY {
    PROPERTIES_EMPTY = 'You must assign at least one property.',
    PROPERTY_IN_USE = ' is already applied to this component.',
}

export const htmlTagNames = [
    'a',
    'abbr',
    'acronym',
    'address',
    'applet',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'base',
    'basefont',
    'bdi',
    'bdo',
    'bgsound',
    'big',
    'blink',
    'blockquote',
    'body',
    'br',
    'button',
    'canvas',
    'caption',
    'center',
    'cite',
    'code',
    'col',
    'colgroup',
    'command',
    'content',
    'data',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'dir',
    'div',
    'dl',
    'dt',
    'element',
    'em',
    'embed',
    'fieldset',
    'figcaption',
    'figure',
    'font',
    'footer',
    'form',
    'frame',
    'frameset',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'head',
    'header',
    'hgroup',
    'hr',
    'html',
    'i',
    'iframe',
    'image',
    'img',
    'input',
    'ins',
    'isindex',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'link',
    'listing',
    'main',
    'map',
    'mark',
    'marquee',
    'math',
    'menu',
    'menuitem',
    'meta',
    'meter',
    'multicol',
    'nav',
    'nextid',
    'nobr',
    'noembed',
    'noframes',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'picture',
    'plaintext',
    'pre',
    'progress',
    'q',
    'rb',
    'rbc',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'script',
    'section',
    'select',
    'shadow',
    'slot',
    'small',
    'source',
    'spacer',
    'span',
    'strike',
    'strong',
    'style',
    'sub',
    'summary',
    'sup',
    'svg',
    'table',
    'tbody',
    'td',
    'template',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'tt',
    'u',
    'ul',
    'var',
    'video',
    'wbr',
    'xmp',
];
