import { Typography, useTheme } from '@mui/material';
import { ROUTES } from '@prbx/consts';
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    const theme = useTheme();
    return (
        <Typography
            variant="h6"
            noWrap
            component={Link}
            to={ROUTES.DASHBOARD}
            sx={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
                pointerEvents: 'auto',
            }}
        >
            PRBX
        </Typography>
    );
};

export default Logo;
