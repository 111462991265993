import React, { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { db, mAuth } from '@prbx/firebase/firebase';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ERROR_REGISTER, ROUTES } from '@prbx/consts';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ref, serverTimestamp, set } from 'firebase/database';
import { FirebaseError } from 'firebase/app';
import { isDisplayNameInUse, sendVerificationEmail } from '@prbx/firebase/auth';

const Register = () => {
    const navigate = useNavigate();
    const [authUser, authLoading] = useAuthState(mAuth);
    const [error, setError] = useState<ERROR_REGISTER | null>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null);
        const data = new FormData(event.currentTarget);
        const displayName = data.get('username')
            ? (data.get('username') as string)
            : '';
        const email = data.get('email')
            ? (data.get('email') as string)
            : 'empty';
        const password = data.get('password')
            ? (data.get('password') as string)
            : 'empty';
        const repeatPassword = data.get('repeat-password')
            ? (data.get('repeat-password') as string)
            : 'empty';
        const acceptTerms = data.get('accept-terms') ? true : false;

        const usernameRegex = /^[a-zA-Z]+$/;

        // ERROR_REGISTER.INVALID_DISPLAYNAME
        if (!usernameRegex.test(displayName) || displayName.length < 6) {
            setError(ERROR_REGISTER.INVALID_DISPLAYNAME);
            return;
        }

        // ERROR_REGISTER.PASSWORDS_NOT_MATCH
        // Password and repeat password are not the same
        if (password !== repeatPassword) {
            setError(ERROR_REGISTER.PASSWORDS_NOT_MATCH);
            return;
        }

        // ERROR_REGISTER.TERMS_NOT_ACCEPTED
        // Terms of use and privacy policy checkbox not checked before submitting
        if (!acceptTerms) {
            setError(ERROR_REGISTER.TERMS_NOT_ACCEPTED);
            return;
        }

        // ERROR_REGISTER.DISPLAYNAME_IN_USE
        // Username is already in use by another user
        if (await isDisplayNameInUse(displayName)) {
            setError(ERROR_REGISTER.DISPLAYNAME_IN_USE);
            return;
        }

        createUserWithEmailAndPassword(mAuth, email, password)
            .then((userCredential) => {
                const createdUser = userCredential.user;
                updateProfile(createdUser, {
                    displayName: displayName,
                }).then(() => {
                    set(
                        ref(db, `/displayNames/${createdUser.uid}/`),
                        displayName
                    );
                    set(ref(db, `/users/${createdUser.uid}/`), {
                        uid: createdUser.uid,
                        displayName: createdUser.displayName,
                        email: createdUser.email,
                        emailVerified: createdUser.emailVerified,
                        authProvider: 'local',
                        lastEmailVerifySent: serverTimestamp(),
                    }).then(() => {
                        sendVerificationEmail(createdUser);
                        navigate(ROUTES.VERIFY_EMAIL);
                    });
                });
            })
            .catch((error: FirebaseError) => {
                switch (error.code) {
                    case 'auth/invalid-email': {
                        // ERROR_REGISTER.INVALID_EMAIL
                        // Entered an invalid email
                        setError(ERROR_REGISTER.INVALID_EMAIL);
                        return;
                    }
                    case 'auth/weak-password': {
                        // ERROR_REGISTER.WEAK_PASSWORD
                        // Entered a password that is too weak
                        setError(ERROR_REGISTER.WEAK_PASSWORD);
                        return;
                    }
                    case 'auth/email-already-in-use': {
                        // ERROR_REGISTER.EMAIL_IN_USE
                        // Email is already in use by another user
                        setError(ERROR_REGISTER.EMAIL_IN_USE);
                        return;
                    }
                    default:
                        alert(error.code);
                }
            });
    };

    useEffect(() => {
        if (authUser) {
            navigate(ROUTES.DASHBOARD);
        }
    }, [authUser]);

    if (authLoading || authUser) {
        return <></>;
    }

    return (
        <Stack
            spacing={2}
            sx={{
                maxWidth: '400px',
                margin: 'auto',
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}
        >
            <Typography
                variant="h3"
                sx={{
                    margin: 'auto',
                    mb: 4,
                }}
            >
                Sign up
            </Typography>
            <TextField
                required
                id="username"
                name="username"
                label="Username"
                error={
                    error == ERROR_REGISTER.INVALID_DISPLAYNAME ||
                    error == ERROR_REGISTER.DISPLAYNAME_IN_USE
                }
                helperText={
                    error == ERROR_REGISTER.INVALID_DISPLAYNAME
                        ? ERROR_REGISTER.INVALID_DISPLAYNAME
                        : error == ERROR_REGISTER.DISPLAYNAME_IN_USE
                        ? ERROR_REGISTER.DISPLAYNAME_IN_USE
                        : ''
                }
                data-testid="register-username"
            />
            <TextField
                required
                id="email"
                name="email"
                autoComplete="email"
                label="Email Address"
                error={
                    error == ERROR_REGISTER.INVALID_EMAIL ||
                    error == ERROR_REGISTER.EMAIL_IN_USE
                }
                helperText={
                    error == ERROR_REGISTER.INVALID_EMAIL
                        ? ERROR_REGISTER.INVALID_EMAIL
                        : error == ERROR_REGISTER.EMAIL_IN_USE
                        ? ERROR_REGISTER.EMAIL_IN_USE
                        : ''
                }
                data-testid="register-email"
            />
            <TextField
                required
                id="password"
                name="password"
                autoComplete="current-password"
                label="Password"
                type="password"
                error={error == ERROR_REGISTER.WEAK_PASSWORD}
                helperText={
                    error == ERROR_REGISTER.WEAK_PASSWORD
                        ? ERROR_REGISTER.WEAK_PASSWORD
                        : ''
                }
                data-testid="register-password"
            />
            <TextField
                required
                id="repeat-password"
                name="repeat-password"
                autoComplete="current-password"
                label="Repeat password"
                type="password"
                error={error == ERROR_REGISTER.PASSWORDS_NOT_MATCH}
                helperText={
                    error == ERROR_REGISTER.PASSWORDS_NOT_MATCH
                        ? ERROR_REGISTER.PASSWORDS_NOT_MATCH
                        : ''
                }
                data-testid="register-repeat-password"
            />
            <Grid container>
                <Grid item>
                    <FormControl
                        error={error == ERROR_REGISTER.TERMS_NOT_ACCEPTED}
                        variant="standard"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={false}
                                    name="accept-terms"
                                    data-testid="register-accept-terms"
                                />
                            }
                            label={
                                <div>
                                    <span>I accept the </span>
                                    <Link
                                        component={RouterLink}
                                        to={ROUTES.TERMS_OF_USE}
                                        variant="body2"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        terms of use
                                    </Link>
                                    <span> and </span>
                                    <Link
                                        component={RouterLink}
                                        to={ROUTES.PRIVACY_POLICY}
                                        variant="body2"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        privacy policy.
                                    </Link>
                                </div>
                            }
                        />
                        {error == ERROR_REGISTER.TERMS_NOT_ACCEPTED && (
                            <FormHelperText data-testid="register-terms-not-accepted">
                                {ERROR_REGISTER.TERMS_NOT_ACCEPTED}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Button
                type="submit"
                variant="contained"
                data-testid="register-sign-up"
            >
                Sign up
            </Button>
        </Stack>
    );
};

export default Register;
