export const muiMenu = {
    styleOverrides: {
        root: ({ theme }) => ({
            '& .MuiPaper-root': {
                boxShadow: 'unset',
                border: `2px solid ${theme.palette.divider}`,
                borderRadius: '8px',
                background: theme.palette.background.paper,
                backgroundImage: 'unset',
            },
        }),
    },
};
