import React, { SVGProps, useMemo } from 'react';
import { useTheme } from '@mui/material';
import Color from 'color';

import { getContrastBorder } from '@prbx/utils/color';
import { ComponentNodeData } from '@prbx/types/Nodes';

type ComponentNodeShapeProps = {
    data?: ComponentNodeData;
    rectProps?: SVGProps<SVGRectElement>;
};

const ComponentNodeShape = ({ data, rectProps }: ComponentNodeShapeProps) => {
    const theme = useTheme();

    const backgroundColor = useMemo(() => {
        if (data && data.canvasColor.enabled) {
            return Color(data.canvasColor.color).hex();
        } else {
            return theme.palette.background.default;
        }
    }, [data, theme.palette.background.default]);

    const needsBorder = useMemo(
        () =>
            Color(backgroundColor).contrast(
                Color(theme.palette.background.default)
            ) < 1.5,
        [backgroundColor, theme.palette.background.default]
    );

    const contrastBorder = useMemo(() => {
        if (needsBorder) {
            return getContrastBorder(backgroundColor);
        } else {
            return theme.palette.text.primary;
        }
    }, [backgroundColor, theme, needsBorder]);

    return (
        <rect
            {...rectProps}
            fill={backgroundColor}
            {...(needsBorder && {
                stroke: contrastBorder,
                strokeWidth: '4px',
            })}
        />
    );
};

export default ComponentNodeShape;
