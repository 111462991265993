import React, { useEffect, useState } from 'react';
import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { getDisplayName } from '@prbx/firebase/auth';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { removeUserFromWorkspace } from '@prbx/firebase/workspace';
import { toast } from 'react-hot-toast';

type InvitedUserProps = {
    id: string;
    uid: string;
    owner?: boolean;
};

const InvitedUser = ({ id, uid, owner }: InvitedUserProps) => {
    const [displayName, setDisplayName] = useState<string>();

    useEffect(() => {
        const getUserDetail = async () => {
            setDisplayName(await getDisplayName(uid));
        };

        getUserDetail();
    });

    if (!displayName) {
        return <Typography>Loading...</Typography>;
    }

    const handleRemoveUser = () => {
        toast(`Removed ${displayName} from the workspace`);
        removeUserFromWorkspace(id, uid);
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '40px 1fr 44px',
                gridTemplateRows: '1fr',
                gap: 2,
                mt: 1,
            }}
        >
            <Avatar alt={displayName} src="/static/images/avatar/2.jpg" />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    noWrap
                    sx={{ alignText: 'center' }}
                    data-testid="inviteduser-displayname"
                >
                    {displayName}
                    {owner && ' (You)'}
                </Typography>
            </Box>
            {!owner && (
                <Tooltip placement="left" title="Remove from workspace">
                    <IconButton
                        color="error"
                        onClick={handleRemoveUser}
                        aria-label="remove-user"
                        data-testid="inviteduser-remove-user"
                    >
                        <PersonRemoveIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

export default InvitedUser;
