import React, { useEffect, useMemo, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    capitalize,
} from '@mui/material';

import { FlowNode, getNodeTypeText, NodeType } from '@prbx/types/Nodes';
import NodeNameTextField from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/NodeNameTextField';
import ComponentNameTextField from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/ComponentNameTextField';
import { isToken } from '@prbx/utils/node';

type AddNodeData = {
    x: number;
    y: number;
    type: NodeType;
};

type AddNodeDialogProps = {
    open: boolean;
    close: () => void;
    data: AddNodeData;
    onAddNode: (name: string) => void;
};

const AddNodeDialog = ({
    open,
    close,
    data,
    onAddNode,
}: AddNodeDialogProps) => {
    const [nodeName, setNodeName] = useState<string>('');
    const [nodeNameError, setNodeNameError] = useState<boolean>(false);

    const type = data.type as NodeType;
    const dummyNode = {
        id: '-1',
        type,
    };

    const isUnsavedChanges = nodeName !== '';

    const nodeTypeText = useMemo(() => getNodeTypeText(type), [type]);

    const nodeIsToken = isToken(type);

    useEffect(() => {
        setNodeName('');
    }, [open]);

    return (
        <Dialog open={open} onClose={close} aria-labelledby="create-node">
            <DialogTitle>
                New {capitalize(nodeTypeText)} {nodeIsToken && 'token'}
            </DialogTitle>
            <DialogContent>
                {nodeIsToken ? (
                    <NodeNameTextField
                        enablePrefix
                        node={dummyNode as unknown as FlowNode}
                        state={nodeName}
                        error={isUnsavedChanges && nodeNameError}
                        setState={setNodeName}
                        setErrorState={setNodeNameError}
                    />
                ) : (
                    <ComponentNameTextField
                        node={dummyNode as unknown as FlowNode}
                        state={nodeName}
                        error={isUnsavedChanges && nodeNameError}
                        setState={setNodeName}
                        setErrorState={setNodeNameError}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={close} data-testid="addnodedialog-cancel">
                    Cancel
                </Button>
                <Button
                    disabled={nodeNameError}
                    onClick={() => {
                        onAddNode(nodeName);
                        close();
                    }}
                    data-testid="addnodedialog-create-node"
                >
                    Create {nodeTypeText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddNodeDialog;
