import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, TextField, Box, Button } from '@mui/material';
import Color from 'color';
import {
    CoreNodeData,
    CoreNodeType,
    NodeType,
    NodeUnit,
} from '@prbx/types/Nodes';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import DeleteNode from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/DeleteNode';
import NodeNameTextField from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/NodeNameTextField';
import { usePrevious } from '@prbx/utils/usePrevious';
import { useReactFlow } from 'reactflow';
import { ERROR_UPDATE_TOKEN } from '@prbx/consts';
import { isValidTokenValue, isValidColor } from '@prbx/utils/node';

type CoreNodeEditorProps = {
    node: CoreNodeType;
};

const CoreNodeEditor = ({ node }: CoreNodeEditorProps) => {
    const instance = useReactFlow();
    const setNodes = instance.setNodes;

    const { id, data, type } = node;
    const { name, token } = data;
    const { unit, value } = token;

    const [coreName, setCoreName] = useState<string>(name);
    const [coreUnit, setCoreUnit] = useState<NodeUnit>(unit);
    const [coreValue, setCoreValue] = useState<string>(value);
    const prevNode = usePrevious(node);

    useEffect(() => {
        if (prevNode && (prevNode as CoreNodeType).id !== id) {
            setCoreName(name);
            setCoreUnit(unit);
            setCoreValue(value);
        }
    }, [node]);

    const [nameError, setNameError] = useState<boolean>(false);
    const valueError = !isValidTokenValue(coreUnit, coreValue);

    const handleValueChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        const nextValue = event.target.value.toString();
        if (isValidColor(nextValue)) {
            setCoreUnit('color');
        } else {
            setCoreUnit('other');
        }
        setCoreValue(nextValue);
    };

    const isUnsavedChanges =
        coreName !== name || coreUnit !== unit || coreValue !== value;

    const canUpdateNode = isUnsavedChanges && !nameError && !valueError;

    const handleUpdate = async () => {
        const newCoreNodeData: CoreNodeData = {
            ...data,
            name: coreName,
            token: {
                unit: coreUnit,
                value: coreValue,
            },
        };

        setNodes((existingNodes) =>
            existingNodes.map((existingNode) => {
                if (existingNode.id === id) {
                    existingNode.data = newCoreNodeData;
                }
                return existingNode;
            })
        );
    };

    return (
        <>
            <FormControl>
                <FormLabel sx={{ mb: 1 }}>Token Name</FormLabel>
                <NodeNameTextField
                    enablePrefix
                    node={node}
                    error={nameError}
                    state={coreName}
                    setState={setCoreName}
                    setErrorState={setNameError}
                />
            </FormControl>
            <FormControl>
                <FormLabel sx={{ mb: 1 }}>Token Value</FormLabel>
                <Box
                    sx={{
                        padding: 0,
                        display: 'flex',
                    }}
                >
                    <FormControl sx={{ flex: '1 0' }}>
                        <TextField
                            variant="filled"
                            value={coreValue}
                            onChange={handleValueChange}
                            error={isUnsavedChanges && valueError}
                            helperText={
                                isUnsavedChanges &&
                                valueError &&
                                ERROR_UPDATE_TOKEN.INVALID_TOKEN_VALUE
                            }
                            InputProps={{
                                ...(!valueError && coreUnit === 'color'
                                    ? {
                                          endAdornment: (
                                              <Box
                                                  component="input"
                                                  className="nodrag"
                                                  type="color"
                                                  onChange={handleValueChange}
                                                  value={Color(coreValue).hex()}
                                                  sx={{
                                                      appearance: 'none',
                                                      width: '32px',
                                                      height: '32px',
                                                      backgroundColor:
                                                          'transparent',
                                                      border: 'none',
                                                      cursor: 'pointer',
                                                      '&::-webkit-color-swatch':
                                                          {
                                                              borderRadius:
                                                                  '2px',
                                                              border: 'none',
                                                          },
                                                  }}
                                                  data-testid="corenodeeditor-colorpicker"
                                              />
                                          ),
                                      }
                                    : valueError && {
                                          endAdornment: (
                                              <ErrorRoundedIcon color="error" />
                                          ),
                                      }),
                            }}
                            InputLabelProps={{ disabled: true }}
                            data-testid="corenodeeditor-textfield-value"
                        />
                    </FormControl>
                </Box>
            </FormControl>
            <Box sx={{ pt: 2 }}>
                <DeleteNode id={id} type={type as NodeType} />
            </Box>
            <Box sx={{ alignSelf: 'flex-end', pt: '16px' }}>
                <Button
                    variant="contained"
                    disabled={!canUpdateNode}
                    onClick={handleUpdate}
                    data-testid="corenodeeditor-update"
                >
                    Update
                </Button>
            </Box>
        </>
    );
};

export default CoreNodeEditor;
