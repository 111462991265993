import React, { useContext, useState } from 'react';
import { Card, Stack } from '@mui/material';
import AddNodeDraggable from '@prbx/components/FlowEditor/AddNodeControl/AddNodeDraggable';
import { FlowNode, NodeType, nodeTypes } from '@prbx/types/Nodes';
import AddNodeDialog from '@prbx/components/FlowEditor/AddNodeControl/AddNodeDialog';
import { generateNodeId, generateNode } from '@prbx/utils/node';
import { useReactFlow } from 'reactflow';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';

type AddNodeControlProps = {
    flowWrapper: React.RefObject<HTMLDivElement>;
};

const AddNodeControl = ({ flowWrapper }: AddNodeControlProps) => {
    const { project } = useReactFlow();
    const { sNodes: nodes, setNodes } = useContext(FlowContext);

    const [addNodeDialogOpen, setAddNodeDialogOpen] = useState<boolean>(false);
    const [nodeDataToCreate, setNodeDataToCreate] = useState<{
        x: number;
        y: number;
        type: NodeType;
    }>({ x: 0, y: 0, type: 'core-token' });

    const createNewNode = (name: string) => {
        const { x, y, type } = nodeDataToCreate;
        if (flowWrapper && flowWrapper.current) {
            const reactFlowBounds = flowWrapper.current.getBoundingClientRect();

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = project({
                x: x - reactFlowBounds.left,
                y: y - reactFlowBounds.top,
            });
            const newId = generateNodeId(nodes as FlowNode[]);
            const newNode = generateNode(
                newId,
                type,
                position.x,
                position.y,
                name
            );

            setNodes((nds) => nds.concat(newNode));
        }
    };

    const handleDrop = (x, y, type) => {
        setAddNodeDialogOpen(true);
        setNodeDataToCreate({ x, y, type });
    };

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    padding: '8px 32px 8px 32px',
                    borderRadius: '64px',
                    borderWidth: '2px',
                    overflow: 'unset',
                }}
            >
                <Stack direction="row" spacing={2}>
                    {nodeTypes.map((type, index) => (
                        <AddNodeDraggable
                            key={index}
                            type={type}
                            onDrop={handleDrop}
                        />
                    ))}
                </Stack>
            </Card>
            <AddNodeDialog
                open={addNodeDialogOpen}
                close={() => setAddNodeDialogOpen(false)}
                data={nodeDataToCreate}
                onAddNode={(name) => createNewNode(name)}
            />
        </>
    );
};

export default AddNodeControl;
