import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Autocomplete, Chip, InputAdornment, TextField } from '@mui/material';
import { FlowNode } from '@prbx/types/Nodes';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import { getIncomingProperties } from '@prbx/utils/getIncomingProperties';
import { Edge, useReactFlow } from 'reactflow';
import { ERROR_CREATE_PROPERTY } from '@prbx/consts';

type EdgePropertiesMultiSelectProps = {
    initialProperties?: string[];
    targetNode: FlowNode;
    propertyValue: string;
    state: string[];
    error: boolean;
    setState: React.Dispatch<React.SetStateAction<string[]>>;
    setErrorState: React.Dispatch<React.SetStateAction<boolean>>;
};

const EdgePropertiesMultiSelect = ({
    initialProperties,
    targetNode,
    propertyValue,
    state,
    error,
    setState,
    setErrorState,
}: EdgePropertiesMultiSelectProps) => {
    const { sEdges: edges } = useContext(FlowContext);
    const propertiesEmptyError = state.length === 0;
    const { getNode } = useReactFlow();

    const propertyAlreadyApplied = useCallback(
        (property: string) => {
            if (initialProperties) {
                return (
                    getIncomingProperties(
                        targetNode,
                        edges as Edge[],
                        getNode
                    ).includes(property) &&
                    !initialProperties.includes(property)
                );
            }

            return getIncomingProperties(
                targetNode,
                edges as Edge[],
                getNode
            ).includes(property);
        },
        [targetNode, edges, initialProperties]
    );

    const propertiesInUse = useMemo(() => {
        const foundProperty = state.filter((property) =>
            propertyAlreadyApplied(property)
        );
        if (foundProperty.length > 0) {
            return { isPropertyInUse: true, property: foundProperty };
        }
        return { isPropertyInUse: false, property: undefined };
    }, [propertyAlreadyApplied, state, error]);

    useEffect(() => {
        setErrorState(propertiesEmptyError || propertiesInUse.isPropertyInUse);
    }, [propertiesEmptyError, propertiesInUse.isPropertyInUse]);

    return (
        <Autocomplete
            multiple
            options={[]}
            freeSolo
            value={state}
            onChange={(event, newValue) => {
                setState(newValue);
            }}
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip
                        {...(propertyAlreadyApplied(option) && {
                            color: 'error',
                        })}
                        label={option}
                        {...getTagProps({ index })}
                        key={index}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            padding: '9px 16px',
                        },
                        '& .MuiInputAdornment-root': {
                            height: 'initial',
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                : {propertyValue};
                            </InputAdornment>
                        ),
                    }}
                    error={error}
                    helperText={
                        error && propertiesEmptyError
                            ? ERROR_CREATE_PROPERTY.PROPERTIES_EMPTY
                            : error && propertiesInUse.isPropertyInUse
                            ? propertiesInUse.property +
                              ERROR_CREATE_PROPERTY.PROPERTY_IN_USE
                            : ''
                    }
                    placeholder="Enter properties..."
                />
            )}
        />
    );
};

export default EdgePropertiesMultiSelect;
