import React from 'react';
import PaletteRoundedIcon from '@mui/icons-material/PaletteRounded';
import SquareFootRoundedIcon from '@mui/icons-material/SquareFootRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

import { NodeUnit } from '@prbx/types/Nodes';

type UnitIconProps = {
    unit: NodeUnit;
};

const UnitIcon = ({ unit }: UnitIconProps) => {
    switch (unit) {
        case 'color':
            return <PaletteRoundedIcon />;
        case 'other':
            return <SquareFootRoundedIcon />;
        default:
            return <QuestionMarkRoundedIcon />;
    }
};

export default UnitIcon;
