import {
    ComponentNodeType,
    CoreNodeType,
    FlowNode,
    NodeType,
    NodeUnit,
    SemanticNodeType,
} from '@prbx/types/Nodes';
import Color from 'color';

export const generateNodeId = (nodes: FlowNode[]): string => {
    if (nodes.length === 0) {
        return '1';
    }
    const maxId = Math.max(...nodes.map((node) => parseInt(node.id)));
    return (maxId + 1).toString();
};

export const generateNode = (
    id: string,
    type: NodeType,
    x: number,
    y: number,
    name: string
): FlowNode => {
    switch (type) {
        case 'core-token':
            return {
                id,
                type,
                dragHandle: '.node-draggable',
                position: {
                    x: x - 82,
                    y: y - 71,
                },
                style: {
                    width: '164px',
                    height: '142px',
                    pointerEvents: 'none',
                },
                data: {
                    name,
                    token: {
                        unit: 'color',
                        value: '#ff0000',
                    },
                },
            } as CoreNodeType;
        case 'semantic-token':
            return {
                id,
                type,
                dragHandle: '.node-draggable',
                position: {
                    x: x - 82,
                    y: y - 48,
                },
                style: {
                    width: '164px',
                    height: '96px',
                    pointerEvents: 'none',
                },
                data: {
                    name,
                },
            } as SemanticNodeType;
        case 'component-token':
            return {
                id,
                type,
                dragHandle: '.node-draggable',
                position: {
                    x: x - 72,
                    y: y - 72,
                },
                style: {
                    width: '142px',
                    height: '142px',
                    pointerEvents: 'none',
                },
                data: {
                    name,
                },
            } as SemanticNodeType;
        case 'component':
            return {
                id,
                type,
                dragHandle: '.node-draggable',
                position: {
                    x,
                    y,
                },
                style: {
                    pointerEvents: 'none',
                },
                data: {
                    name,
                    component: 'button',
                    childText: {
                        enabled: true,
                        text: name,
                    },
                    canvasColor: {
                        enabled: false,
                        color: '#FFFFFF',
                    },
                },
            } as ComponentNodeType;
    }
};

export const isToken = (type: NodeType) =>
    type === 'core-token' ||
    type === 'semantic-token' ||
    type === 'component-token';

export const isNodeNameInUse = (
    nodes: FlowNode[] | undefined,
    node: FlowNode,
    name: string
) => {
    if (!nodes) return false;
    return nodes.some((n) => {
        return n.id !== node.id && n.type == node.type && n.data.name == name;
    });
};

export const isValidColor = (color: string) => {
    try {
        Color(color).hex();
        return true;
    } catch {
        return false;
    }
};

export const isValidTokenValue = (unit: NodeUnit, value: string) => {
    if (unit == 'color') {
        return isValidColor(value);
    }
    if (unit == 'other') {
        return value.length > 0;
    }
};

const nameRegex = /^[\w-]+$/;
export const isValidNodeName = (name: string) =>
    nameRegex.test(name) && name.length > 0;
