import React, { SVGProps, useMemo } from 'react';
import { useTheme } from '@mui/material';
import Color from 'color';

import { generateShapeColor, getContrastBorder } from '@prbx/utils/color';
import { NodeTokenValue } from '@prbx/types/Nodes';

type SemanticTokenShapeProps = {
    token?: NodeTokenValue;
    svgProps?: SVGProps<SVGSVGElement>;
    rectProps?: SVGProps<SVGRectElement>;
};

const SemanticTokenShape = ({
    token,
    svgProps,
    rectProps,
}: SemanticTokenShapeProps) => {
    const theme = useTheme();

    const shapeColor = useMemo(
        () => generateShapeColor(theme, token),
        [theme, token]
    );

    const needsBorder = useMemo(
        () =>
            Color(shapeColor).contrast(
                Color(theme.palette.background.default)
            ) < 1.5,
        [shapeColor, theme.palette.background.default]
    );

    const contrastBorder = useMemo(
        () => getContrastBorder(shapeColor),
        [shapeColor]
    );

    const appliedRectProps = {
        fill: shapeColor,
        ...(needsBorder && {
            stroke: contrastBorder,
            strokeWidth: '4px',
        }),
        ...rectProps,
    };

    return (
        <svg
            {...svgProps}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 164 96"
        >
            <clipPath id="semanticIconClip">
                <rect width="164" height="96" {...appliedRectProps} rx="8" />
            </clipPath>
            <rect
                width="164"
                height="96"
                {...appliedRectProps}
                rx="8"
                clipPath="url(#semanticIconClip)"
            />
        </svg>
    );
};

export default SemanticTokenShape;
