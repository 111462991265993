import React, { createContext } from 'react';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '@prbx/firebase/firebase';
import { useObject } from 'react-firebase-hooks/database';
import { ref } from 'firebase/database';
import { ROUTES } from '@prbx/consts';
import {
    getSharedWithUids,
    Workspace,
    WorkspaceDetail,
} from '@prbx/firebase/workspace';
import FlowEditor from '@prbx/components/FlowEditor/FlowEditor';
import ConnectedUserManager from '@prbx/components/Editor/ConnectedUserManager/ConnectedUserManager';
import { User } from 'firebase/auth';

export const WorkspaceContext = createContext<WorkspaceDetail>(
    {} as WorkspaceDetail
);

type EditorProps = {
    user: User;
};

const Editor = ({ user }: EditorProps) => {
    const navigate = useNavigate();
    const { id } = useParams();

    // If the id is empty, redirect home
    if (!id) {
        navigate(ROUTES.DASHBOARD);
        return <></>;
    }

    // Fetch  workspace
    const [workspaceSnapshot, workspaceSnapshotLoading] = useObject(
        ref(db, `/workspaces/${id}`)
    );
    // TODO possibly move these inside a component for data security?
    const [nodesSnapshot, nodesSnapshotLoading] = useObject(
        ref(db, `/workspaces/${id}/nodes`)
    );
    const [edgesSnapshot, edgesSnapshotLoading] = useObject(
        ref(db, `/workspaces/${id}/edges`)
    );

    // Validates data is not waiting to be fetched/loading
    if (
        workspaceSnapshotLoading ||
        nodesSnapshotLoading ||
        edgesSnapshotLoading
    ) {
        return (
            <Typography mt={16} ml={16}>
                Loading...
            </Typography>
        );
    }

    // User found, define user
    const { uid } = user;

    // Validates workspace exists
    if (!workspaceSnapshot?.val()) {
        return (
            <Typography mt={16} ml={16} data-testid="editor-not-found">
                Not found
            </Typography>
        );
    }

    // Workspace found, define workspace and nodes/edges
    const workspace = workspaceSnapshot.val() as Workspace;
    const { createdBy, sharedWith } = workspace;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { nodes, edges, ...workspaceDetail } = workspace;
    const serverNodeData = nodesSnapshot?.val() ?? { nodes: [], version: 0 };
    const serverEdgeData = edgesSnapshot?.val() ?? { edges: [], version: 0 };

    // Check if user has permission to view workspace
    const hasPermission =
        createdBy == uid || getSharedWithUids(sharedWith).includes(uid);

    if (hasPermission) {
        return (
            <WorkspaceContext.Provider value={workspaceDetail}>
                <ConnectedUserManager id={id} user={user}>
                    <FlowEditor
                        id={id}
                        user={user}
                        serverNodeData={serverNodeData}
                        serverEdgeData={serverEdgeData}
                    />
                </ConnectedUserManager>
            </WorkspaceContext.Provider>
        );
    } else {
        return (
            <Typography mt={16} ml={16} data-testid="editor-no-permission">
                No permission
            </Typography>
        );
    }
};

export default Editor;
