import React from 'react';
import { Menu, MenuItem, Typography, Divider } from '@mui/material';
import ColorModeToggle from '@prbx/components/Navigation/ColorModeToggle';
import { signOutUser } from '@prbx/firebase/auth';
import { User } from 'firebase/auth';

type AccountMenuProps = {
    anchor: null | HTMLElement;
    closeMenu: () => void;
    user: User;
};

const AccountMenu = ({ anchor, closeMenu, user }: AccountMenuProps) => {
    return (
        <Menu
            sx={{
                mt: '45px',
            }}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchor)}
            onClose={closeMenu}
        >
            <MenuItem key="account" onClick={closeMenu}>
                <Typography textAlign="center">Account</Typography>
            </MenuItem>
            <MenuItem
                key="signOut"
                onClick={() => {
                    signOutUser(user);
                    closeMenu();
                }}
                data-testid="accountmenu-sign-out"
            >
                <Typography textAlign="center">Sign out</Typography>
            </MenuItem>
            <Divider />
            <ColorModeToggle />
        </Menu>
    );
};

export default AccountMenu;
