import { FlowNode, NodeType } from '@prbx/types/Nodes';
import getFormattedNodeName from '@prbx/utils/getFormattedNodeName';
import { toCamelCase } from '@prbx/utils/toCamelCase';
import { getUpstreamCoreToken } from '@prbx/utils/upstreamAttributes';
import { Edge, getConnectedEdges } from 'reactflow';

const validCssValue = (cssProp, val) => {
    if (cssProp == 'length') return false;
    if (val == '') return true;
    const style = new Option().style;
    if (style[cssProp] != '') return false;
    style[cssProp] = val;
    return style[cssProp] !== '';
};

export const TESTING_validCssValue = validCssValue;

const generateStyles = (
    connectedEdges: Edge[],
    getNode,
    sNodes: FlowNode[],
    sEdges: Edge[]
): React.CSSProperties => {
    let newComponentStyles: React.CSSProperties = {};
    connectedEdges.forEach((edge) => {
        const sourceNode = getNode(edge.source);
        if (sourceNode) {
            let coreToken;
            if (sourceNode.type === 'core-token') {
                coreToken = sourceNode;
            } else {
                coreToken = getUpstreamCoreToken(sourceNode, sNodes, sEdges);
            }
            if (coreToken) {
                newComponentStyles = {
                    ...newComponentStyles,
                    ...edge.data.properties.reduce((acc, property) => {
                        if (
                            validCssValue(
                                toCamelCase(property),
                                coreToken.data.token.value
                            )
                        ) {
                            acc[toCamelCase(property)] =
                                coreToken.data.token.value;
                        }
                        return acc;
                    }, {}),
                };
            }
        }
    });
    return newComponentStyles;
};

const generateStyleApplications = (
    prefix: string,
    connectedEdges: Edge[],
    getNode,
    sNodes: FlowNode[],
    sEdges: Edge[]
): string =>
    connectedEdges.reduce((acc, edge) => {
        const sourceNode = getNode(edge.source);

        if (sourceNode) {
            let coreToken;
            if (sourceNode.type === 'core-token') {
                coreToken = sourceNode;
            } else {
                coreToken = getUpstreamCoreToken(sourceNode, sNodes, sEdges);
            }
            if (coreToken) {
                return (
                    acc +
                    edge.data.properties.reduce((acc, property) => {
                        return (
                            acc +
                            `\n    ${property}: ${getFormattedNodeName(
                                prefix,
                                sourceNode.type as NodeType,
                                sourceNode.data.name
                            )};`
                        );
                    }, ``)
                );
            }
        }
        return acc;
    }, ``);

export const getStyleApplications = (
    prefix: string,
    connectedEdges: Edge[],
    getNode,
    sNodes: FlowNode[],
    sEdges: Edge[]
) => {
    let styleApplicationsToReturn = ``;
    connectedEdges.forEach((edge) => {
        const sourceNode = getNode(edge.source) as FlowNode;
        if (sourceNode?.type === 'component') {
            const sourceConnectedEdges = getConnectedEdges(
                [sourceNode],
                sEdges as Edge[]
            ).filter((edge) => edge.target === sourceNode.id);
            styleApplicationsToReturn += getStyleApplications(
                prefix,
                sourceConnectedEdges,
                getNode,
                sNodes,
                sEdges
            );
        }
    });
    const filteredConnectedEdges = connectedEdges.filter(
        (edge) => getNode(edge.source)?.type !== 'component'
    );

    const generatedStyleApplications = generateStyleApplications(
        prefix,
        filteredConnectedEdges,
        getNode,
        sNodes as FlowNode[],
        sEdges
    );
    styleApplicationsToReturn += generatedStyleApplications;
    return styleApplicationsToReturn;
};

export const getParentStyles = (
    connectedEdges: Edge[],
    getNode,
    sNodes: FlowNode[],
    sEdges: Edge[]
): React.CSSProperties => {
    let stylesToReturn: React.CSSProperties = {};
    connectedEdges.forEach((edge) => {
        const sourceNode = getNode(edge.source) as FlowNode;
        if (sourceNode?.type === 'component') {
            const sourceConnectedEdges = getConnectedEdges(
                [sourceNode],
                sEdges as Edge[]
            ).filter((edge) => edge.target === sourceNode.id);
            stylesToReturn = {
                ...stylesToReturn,
                ...getParentStyles(
                    sourceConnectedEdges,
                    getNode,
                    sNodes,
                    sEdges
                ),
            };
        }
    });
    const filteredConnectedEdges = connectedEdges.filter(
        (edge) => getNode(edge.source)?.type !== 'component'
    );

    const generatedStyles = generateStyles(
        filteredConnectedEdges,
        getNode,
        sNodes as FlowNode[],
        sEdges
    );
    stylesToReturn = {
        ...stylesToReturn,
        ...generatedStyles,
    };

    return stylesToReturn;
};
