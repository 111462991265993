import React from 'react';
import { useReactFlow } from 'reactflow';

import ConfirmationButton from '@prbx/components/Dashboard/ConfirmationButton';

type DeleteNodeProps = {
    id: string;
};

const DeleteEdge = ({ id }: DeleteNodeProps) => {
    const { deleteElements, getEdge } = useReactFlow();
    const edgeToDelete = getEdge(id);

    const handleDelete = () => {
        if (edgeToDelete) {
            deleteElements({ edges: [edgeToDelete] });
        }
    };

    return (
        <ConfirmationButton
            actionText={`delete the edge`}
            variant="outlined"
            onConfirm={handleDelete}
            color="error"
            fullWidth
        >
            Delete edge
        </ConfirmationButton>
    );
};

export default DeleteEdge;
