export const muiTextField = {
    styleOverrides: {
        root: ({ theme }) => ({
            '& .MuiOutlinedInput-input': {
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
            },
            '& input:-internal-autofill-selected': {
                borderRadius: '32px',
                background: 'none',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '32px',
                    borderWidth: '2px',
                    padding: '16px',
                    top: '-4px',
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                    borderWidth: '2px',
                },
                '& legend': {
                    fontSize: '0.9em',
                    transform: 'translate(24px, -14px)',
                },
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(24px, -10px) scale(0.9)',
            },
            '& .MuiFilledInput-input': {
                paddingTop: '8px',
            },
            '& .MuiFilledInput-root::after': {
                borderWidth: '2px',
            },
            '& .MuiFilledInput-root::before': {
                borderWidth: '2px !important',
            },
            '& .MuiInputAdornment-filled': {
                marginTop: '0 !important',
            },
        }),
    },
    defaultProps: {
        InputLabelProps: { shrink: true },
    },
};
