import React, { memo, useContext, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ComponentTokenShape from '@prbx/components/Nodes/ComponentTokenShape';
import SemanticTokenShape from '@prbx/components/Nodes/SemanticTokenShape';
import UnitIcon from '@prbx/components/Nodes/UnitIcon';
import { NodeData } from '@prbx/types/Nodes';
import { NodeProps, Position } from 'reactflow';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import { generateShapeColor } from '@prbx/utils/color';
import useToken from '@prbx/utils/useToken';
import { StyledHandle } from '@prbx/components/Nodes/StyledHandle';

const SemanticToken = ({ id, type, data }: NodeProps<NodeData>) => {
    const { prefix } = useContext(WorkspaceContext);
    const theme = useTheme();
    const { token } = useToken(id);
    const { name } = data;

    const shapeColor = useMemo(
        () => generateShapeColor(theme, token),
        [theme, token]
    );

    const contrastText = theme.palette.getContrastText(shapeColor);

    const tokenName = `--${prefix}-${type.split('-')[0]}-${name}`;

    return (
        <>
            {type === 'semantic-token' && (
                <SemanticTokenShape
                    svgProps={{
                        className: 'node-draggable',
                        style: {
                            cursor: 'pointer',
                            pointerEvents: 'all',
                        },
                    }}
                    token={token}
                />
            )}
            {type === 'component-token' && (
                <ComponentTokenShape
                    svgProps={{
                        className: 'node-draggable',
                        style: {
                            cursor: 'pointer',
                            pointerEvents: 'all',
                        },
                        height: '142',
                        width: '142',
                    }}
                    token={token}
                />
            )}
            <Box
                sx={{
                    width: '100%',
                    top: `${type === 'component-token' ? '35%' : '25%'}`,
                    alignItems: 'center',
                    position: 'absolute',
                    padding: '0px 16px',
                    cursor: 'pointer',
                    textAlign: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: contrastText,
                    }}
                >
                    {token ? (
                        <>
                            <UnitIcon unit={token.unit} />
                            <Typography
                                sx={{
                                    ml: 1,
                                    fontSize: '16px',
                                    fontWeight: 600,
                                }}
                                noWrap
                                data-testid={`semantictoken-value-${id}`}
                            >
                                {token.value}
                            </Typography>
                        </>
                    ) : (
                        <Typography
                            sx={{
                                ml: 1,
                                fontSize: '16px',
                                fontWeight: 600,
                            }}
                        >
                            No data!
                        </Typography>
                    )}
                </Box>
                <Typography
                    sx={{
                        color: contrastText,
                        fontSize: '10px',
                        overflow: 'hidden',
                        overflowWrap: 'anywhere',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                    }}
                    data-testid={`semantictoken-name-${id}`}
                >
                    {tokenName}
                </Typography>
            </Box>
            <StyledHandle
                type="target"
                position={Position.Top}
                id={`${type}-target`}
            />
            <StyledHandle
                type="source"
                position={Position.Bottom}
                id={`${type}-source`}
            />
        </>
    );
};

export default memo(SemanticToken);
