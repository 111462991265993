import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
    'fontFamily': `"Lexend", "Helvetica", "Arial", sans-serif`,
    'fontSize': 16,
    'fontWeightLight': 300,
    'fontWeightRegular': 400,
    'fontWeightMedium': 500,
    'fontWeightBold': 600,
    'allVariants': {
        fontSize: 16,
    },
    'h1': {
        fontSize: 128,
    },
    'h2': {
        fontSize: 80,
    },
    'h3': {
        fontSize: 64,
        fontWeight: 600,
    },
    'h4': {
        fontSize: 48,
    },
    'h5': {
        fontSize: 32,
    },
    'h6': {
        fontSize: 24,
    },
    'button': {
        textTransform: 'unset',
    },
    'caption': {
        fontSize: 16,
    },
};
