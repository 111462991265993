import { Box, Typography } from '@mui/material';
import WorkspaceCard from '@prbx/components/Dashboard/Workspaces/WorkspaceCard';
import { Workspace } from '@prbx/firebase/workspace';
import { User } from 'firebase/auth';
import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

type WorkspacesProps = {
    user: User;
    workspaces: Record<string, Workspace>;
    loading: boolean;
};

const Workspaces = ({ user, workspaces, loading }: WorkspacesProps) => {
    if (loading) {
        return <Typography>Loading...</Typography>;
    }
    if (workspaces) {
        if (Object.keys(workspaces).length == 0) {
            return <Typography>Empty...</Typography>;
        }
        if (Object.keys(workspaces).length > 1) {
            workspaces = Object.entries(workspaces)
                .map(([key, value]) => ({ key, value }))
                .sort(
                    (a, b) => b.value.lastEdited.time - a.value.lastEdited.time
                )
                .reduce(
                    (acc, { key, value }) => ({ ...acc, [key]: value }),
                    {}
                );
        }
        return (
            <Box sx={{ maxWidth: '1500px', pr: 1 }}>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{
                        350: 1,
                        600: 2,
                        1400: 3,
                        2000: 4,
                    }}
                >
                    <Masonry gutter="16px">
                        {Object.keys(workspaces).map((key) => {
                            return (
                                <WorkspaceCard
                                    user={user}
                                    key={key}
                                    id={key}
                                    workspace={workspaces[key]}
                                />
                            );
                        })}
                    </Masonry>
                </ResponsiveMasonry>
            </Box>
        );
    }
    return <></>;
};

export default Workspaces;
