import { FlowNode } from '@prbx/types/Nodes';
import { Edge, getIncomers } from 'reactflow';

const getAllIncomers = (
    node: FlowNode,
    nodes: FlowNode[],
    edges: Edge[],
    prevIncomers: FlowNode[] = []
) => {
    const incomers = getIncomers(node, nodes, edges);
    const result = incomers.reduce((memo: FlowNode[], incomer) => {
        memo.push(incomer);

        if (prevIncomers.findIndex((n) => n.id == incomer.id) == -1) {
            prevIncomers.push(incomer);

            getAllIncomers(incomer, nodes, edges, prevIncomers).forEach(
                (foundNode) => {
                    memo.push(foundNode);

                    if (
                        prevIncomers.findIndex((n) => n.id == foundNode.id) ==
                        -1
                    ) {
                        prevIncomers.push(incomer);
                    }
                }
            );
        }
        return memo;
    }, []);
    return result;
};

export default getAllIncomers;
