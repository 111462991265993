import React, { memo, useContext, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CoreTokenShape from '@prbx/components/Nodes/CoreTokenShape';
import UnitIcon from '@prbx/components/Nodes/UnitIcon';
import { CoreNodeData, NodeType } from '@prbx/types/Nodes';

import { generateShapeColor } from '@prbx/utils/color';
import { NodeProps, Position } from 'reactflow';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import { StyledHandle } from '@prbx/components/Nodes/StyledHandle';
import getFormattedNodeName from '@prbx/utils/getFormattedNodeName';

const CoreToken = ({ data, type }: NodeProps<CoreNodeData>) => {
    const { prefix } = useContext(WorkspaceContext);
    const theme = useTheme();
    const { name, token } = data;

    const shapeColor = useMemo(
        () => generateShapeColor(theme, token),
        [theme, token]
    );

    const contrastText = theme.palette.getContrastText(shapeColor);

    return (
        <>
            <CoreTokenShape
                overrideShapeColor={shapeColor}
                pathProps={{
                    className: 'node-draggable',
                    style: {
                        cursor: 'pointer',
                        pointerEvents: 'all',
                    },
                }}
            />
            <Box
                sx={{
                    width: '100%',
                    height: '60%',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '30%',
                    flexDirection: 'column',
                    color: contrastText,
                }}
            >
                <UnitIcon unit={token.unit} />
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize: '16px',
                        maxWidth: '55%',
                        fontWeight: 600,
                    }}
                    noWrap
                    data-testid="coretoken-value"
                >
                    {token.value}
                </Typography>
                <Typography
                    sx={{
                        textAlign: 'center',
                        maxWidth: '65%',
                        fontSize: '10px',
                        overflow: 'hidden',
                        overflowWrap: 'anywhere',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                    }}
                    data-testid="coretoken-name"
                >
                    {getFormattedNodeName(prefix, type as NodeType, name)}
                </Typography>
            </Box>
            <StyledHandle
                type="source"
                position={Position.Bottom}
                id={`${type}-source`}
            />
        </>
    );
};

export default memo(CoreToken);
