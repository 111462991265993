import React, { useContext, useState } from 'react';
import { Card, IconButton, Tooltip } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { User } from 'firebase/auth';

import EditableWorkspaceName from '@prbx/components/Dashboard/EditableWorkspaceName';
import WorkspaceSettingsDialog from '@prbx/components/Dashboard/Workspaces/WorkspaceSettingsDialog';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import ExportCode from '@prbx/components/FlowEditor/ExportCode';

type HeaderProps = {
    id: string;
    user: User;
};

const Header = ({ id, user }: HeaderProps) => {
    const workspaceDetail = useContext(WorkspaceContext);
    const { name } = workspaceDetail;
    const [isEditingName, setIsEditingName] = useState(false);

    // Workspace settings dialog menu
    const [workspaceSettingsDialogOpen, setWorkspaceSettingsDialogOpen] =
        useState<boolean>(false);

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    minHeight: '64px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px 16px 0px 32px',
                    borderRadius: '64px',
                    borderWidth: '2px',
                }}
            >
                <WorkspacesIcon color="primary" sx={{ mr: 1 }} />
                <EditableWorkspaceName
                    variant="h6"
                    sx={{ mr: 2 }}
                    id={id}
                    name={name}
                    isEditing={isEditingName}
                    setIsEditing={setIsEditingName}
                    user={user}
                />
                <ExportCode />
                <Tooltip placement="bottom" title="Workspace settings">
                    <IconButton
                        onClick={() => setWorkspaceSettingsDialogOpen(true)}
                        aria-label="workspace-settings"
                        data-testid="header-workspace-settings"
                    >
                        <SettingsRoundedIcon />
                    </IconButton>
                </Tooltip>
            </Card>
            <WorkspaceSettingsDialog
                open={workspaceSettingsDialogOpen}
                close={() => setWorkspaceSettingsDialogOpen(false)}
                id={id}
                workspaceDetail={workspaceDetail}
                user={user}
            />
        </>
    );
};

export default Header;
