import React, { useContext, useEffect, useState } from 'react';
import { Edge, useOnSelectionChange } from 'reactflow';

import { FlowNode } from '@prbx/types/Nodes';
import NodeEditor from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/NodeEditor';
import EdgeEditor from '@prbx/components/FlowEditor/ElementEditor/EdgeEditor/EdgeEditor';
import { Box, Stack, useTheme } from '@mui/material';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';

const ElementEditor = () => {
    const theme = useTheme();
    const { sNodes, sEdges } = useContext(FlowContext);

    const [selectedNode, setSelectedNode] = useState<FlowNode | null>();
    const [selectedEdge, setSelectedEdge] = useState<Edge | null>();

    useOnSelectionChange({
        onChange: ({ nodes, edges }) => {
            if (nodes.length === 0) {
                setSelectedNode(null);
            }
            if (edges.length === 0) {
                setSelectedEdge(null);
            }
        },
    });

    useEffect(() => {
        const newSelectedNode = sNodes.find((n) => n.selected);
        if (newSelectedNode) {
            setSelectedNode(newSelectedNode as FlowNode);
            setSelectedEdge(null);
        }
    }, [sNodes, sEdges]);
    useEffect(() => {
        const newSelectedEdge = sEdges.find((edge) => edge.selected);
        if (newSelectedEdge) {
            setSelectedNode(null);
            setSelectedEdge(newSelectedEdge as Edge);
        }
    }, [sEdges]);

    return (
        <>
            {(selectedNode || selectedEdge) && (
                <Box
                    sx={{
                        background: theme.palette.background.default,
                        border: `2px solid ${theme.palette.divider}`,
                        borderRadius: '8px',
                        padding: '24px 24px',
                        width: '400px',
                    }}
                >
                    <Stack spacing={2}>
                        {selectedNode && (
                            <NodeEditor selectedNode={selectedNode} />
                        )}
                        {selectedEdge && (
                            <EdgeEditor selectedEdge={selectedEdge} />
                        )}
                    </Stack>
                </Box>
            )}
        </>
    );
};

export default ElementEditor;
