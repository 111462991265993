import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import { useReactFlow } from 'reactflow';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import {
    generateTokenApplications,
    generateTokenDefinitions,
    generateTokenJSON,
} from '@prbx/utils/code';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import { FlowNode } from '@prbx/types/Nodes';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import { toast } from 'react-hot-toast';
import saveAs from 'file-saver';

type ExportCodeDialogProps = {
    open: boolean;
    close: () => void;
};

const ExportCodeDialog = ({ open, close }: ExportCodeDialogProps) => {
    const { sNodes, sEdges } = useContext(FlowContext);
    const { name, prefix } = useContext(WorkspaceContext);
    const { getNode } = useReactFlow();

    const [codeString, setCodeString] = useState<string>('');

    useEffect(() => {
        if (open) {
            setCodeString(
                `/* Token definitions */\n${generateTokenDefinitions(
                    prefix,
                    sNodes as FlowNode[],
                    sEdges
                )}\n/* Token applications */\n${generateTokenApplications(
                    prefix,
                    sNodes as FlowNode[],
                    sEdges,
                    getNode
                )}`
            );
        }
    }, [open]);

    const handleClose = () => {
        close();
    };

    const handleClick = () => {
        navigator.clipboard.writeText(codeString);
        toast('Code copied to clipboard');
    };

    const handleSaveJSON = () => {
        const fileName = `${name}-export.tokens.json`;
        const generatedTokenJSON = generateTokenJSON(
            prefix,
            sNodes as FlowNode[],
            sEdges
        );
        const fileToSave = new Blob([JSON.stringify(generatedTokenJSON)], {
            type: 'application/json',
        });
        saveAs(fileToSave, fileName);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="export-to-code"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle>Token definitions and applications</DialogTitle>

            <DialogContent>
                <IconButton
                    onClick={handleClick}
                    sx={{
                        position: 'absolute',

                        right: '32px',
                        margin: 2,
                    }}
                >
                    <ContentCopyIcon />
                </IconButton>
                <Box
                    sx={{ '& pre': { margin: '0px' } }}
                    data-testid="exportcodedialog-css"
                >
                    <SyntaxHighlighter language="css" style={github}>
                        {codeString}
                    </SyntaxHighlighter>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleSaveJSON}
                    data-testid="exportcodedialog-json"
                >
                    Export as Design Tokens JSON...
                </Button>
                <Button variant="contained" onClick={handleClose}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ExportCode = () => {
    const [exportCodeDialogOpen, setExportCodeDialogOpen] = useState(false);

    return (
        <>
            <Tooltip placement="bottom" title="Export workspace to code">
                <IconButton
                    onClick={() => setExportCodeDialogOpen(true)}
                    data-testid="exportcode-button"
                >
                    <CodeIcon />
                </IconButton>
            </Tooltip>
            <ExportCodeDialog
                open={exportCodeDialogOpen}
                close={() => setExportCodeDialogOpen(false)}
            />
        </>
    );
};

export default ExportCode;
