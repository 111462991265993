import React from 'react';
import { Card, CardActionArea, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useAuthState } from 'react-firebase-hooks/auth';
import { push, ref, serverTimestamp } from 'firebase/database';
import { db, mAuth } from '@prbx/firebase/firebase';
import { Workspace } from '@prbx/firebase/workspace';

const CreateWorkspace = () => {
    const [user] = useAuthState(mAuth);

    const createWorkspace = () => {
        if (user) {
            const newWorkspace: Workspace = {
                name: 'Untitled workspace',
                createdBy: user.uid,
                sharedWith: [],
                lastEdited: {
                    time: serverTimestamp() as unknown as number,
                    by: user.uid,
                },
                prefix: 'prefix',
                nodes: [],
                edges: [],
            };

            // TODO maybe will have to handle this as /workspaces/detail and /workspaces/data, to seperate concerns
            push(ref(db, `/workspaces/`), newWorkspace)
                .then((result) => {
                    window.open(`/workspace/${result.key}`);
                })
                .catch(() => {
                    // TODO handle error here
                    // console.log(error.code);
                });
        }
    };

    return (
        <Card
            variant="outlined"
            sx={{
                width: 'fit-content',
                borderWidth: '2px',
                borderRadius: '8px',
                mb: 4,
                minWidth: 250,
            }}
        >
            <CardActionArea
                sx={{ display: 'flex', alignItems: 'center', padding: 2 }}
                onClick={createWorkspace}
                data-testid="createworkspace-create-workspace"
            >
                <WorkspacesIcon color="primary" sx={{ mr: 2 }} />
                <Typography variant="subtitle1" mr={2}>
                    New workspace
                </Typography>
                <AddIcon />
            </CardActionArea>
        </Card>
    );
};

export default CreateWorkspace;
