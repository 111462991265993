import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Stack,
    Typography,
} from '@mui/material';
import { APP_NAME, ROUTES } from '@prbx/consts';
import { useIdToken } from 'react-firebase-hooks/auth';
import { db, mAuth } from '@prbx/firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { onValue, ref } from 'firebase/database';
import { sendVerificationEmail } from '@prbx/firebase/auth';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const [user, loading] = useIdToken(mAuth);

    const [redirectCountdown, setRedirectCountdown] = useState(4);
    const [emailTimeout, setEmailTimeout] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [canClick, setCanClick] = useState<boolean | null>(null);
    const [justSentEmail, setJustSentEmail] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            onValue(
                ref(db, `/users/${user.uid}/lastEmailVerifySent`),
                (snapshot) => {
                    const data = snapshot.val();
                    setEmailTimeout(data);
                }
            );
        }
    }, [user]);

    const calculateTimeout = (auto: boolean) => {
        const timeDifference = Date.now() - (emailTimeout ?? Date.now());
        if (timeDifference > 59000) {
            setCanClick(true);
            if (auto && user) {
                sendVerificationEmail(user);
            }
        } else {
            setJustSentEmail(true);
            setCanClick(false);
        }

        setRemainingTime(Math.floor(60 - timeDifference / 1000));
    };

    useEffect(() => {
        if (user) {
            const interval = setInterval(() => {
                user.reload();
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [user, loading]);

    useEffect(() => {
        if (emailTimeout) {
            calculateTimeout(true);
            const interval = setInterval(() => calculateTimeout(false), 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [emailTimeout]);

    useEffect(() => {
        if (!loading && !user) {
            navigate(ROUTES.DASHBOARD);
        } else if (user && user.emailVerified) {
            setRedirectCountdown(redirectCountdown - 1);
        }
    }, [user, loading, user?.emailVerified]);

    useEffect(() => {
        if (user && user.emailVerified) {
            setTimeout(() => {
                setRedirectCountdown(redirectCountdown - 1);
            }, 1000);
        }
        if (redirectCountdown == 0) {
            navigate(ROUTES.DASHBOARD);
        }
    }, [redirectCountdown]);

    if (!user) {
        return <></>;
    }

    if (user && user.emailVerified) {
        return (
            <Stack
                spacing={2}
                sx={{ maxWidth: '600px', margin: 'auto', mt: '32px' }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        mb: 2,
                    }}
                >
                    Email verified
                </Typography>
                <Typography>
                    Your email has been verified.
                    <br />
                    <br />
                    You will be redirected in {redirectCountdown} seconds...
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: '600px', margin: 'auto', mt: '32px' }}
        >
            <Typography
                variant="h3"
                sx={{
                    mb: 2,
                }}
                data-testid="verifyemail-title"
            >
                Verify your email
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: '16px !important',
                }}
            >
                <Box>
                    <CircularProgress />
                </Box>
                {justSentEmail ? (
                    <Typography ml={3}>
                        Hi {user.displayName}! To start using {APP_NAME}, you
                        must verify your email.
                        <br />
                        An email with instructions for verifying your account
                        has been sent to {<b>{user.email}</b>}.
                    </Typography>
                ) : (
                    <Typography ml={3}>
                        Hi {user.displayName}! To start using {APP_NAME}, you
                        must verify your email.
                    </Typography>
                )}
            </Box>
            {typeof canClick === 'boolean' && (
                <Button
                    onClick={() => {
                        sendVerificationEmail(user);
                        setJustSentEmail(true);
                    }}
                    disabled={!canClick}
                    variant="contained"
                    fullWidth
                >
                    {canClick
                        ? 'Resend verification email'
                        : `Wait ${remainingTime} seconds before sending a new verification email...`}
                </Button>
            )}
        </Stack>
    );
};

export default VerifyEmail;
