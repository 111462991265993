import React, { useContext, useEffect } from 'react';
import { TextField } from '@mui/material';
import { FlowNode } from '@prbx/types/Nodes';
import { ERROR_UPDATE_COMPONENT } from '@prbx/consts';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import { isNodeNameInUse } from '@prbx/utils/node';

type ComponentNameTextFieldProps = {
    node: FlowNode;
    state: string;
    error: boolean;
    setState: React.Dispatch<React.SetStateAction<string>>;
    setErrorState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ComponentNameTextField = ({
    node,
    state,
    error,
    setState,
    setErrorState,
}: ComponentNameTextFieldProps) => {
    const { sNodes: nodes } = useContext(FlowContext);
    const nameInvalidError = state.length === 0;
    const nameInUseError = isNodeNameInUse(nodes as FlowNode[], node, state);

    useEffect(() => {
        setErrorState(nameInvalidError || nameInUseError);
    }, [state, nodes, node]);

    const handleNameChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        const nextName = event.target.value.toString();
        setState(nextName);
    };

    return (
        <TextField
            fullWidth
            variant="filled"
            value={state}
            error={error}
            helperText={
                nameInvalidError && error
                    ? ERROR_UPDATE_COMPONENT.INVALID_NAME
                    : nameInUseError && error
                    ? ERROR_UPDATE_COMPONENT.NAME_IN_USE
                    : ''
            }
            onChange={handleNameChange}
            InputLabelProps={{ disabled: true }}
            data-testid="componentnametextfield-textfield"
        />
    );
};

export default ComponentNameTextField;
