import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import NavigationAccount from '@prbx/components/Navigation/NavigationAccount';
import Logo from '@prbx/components/Navigation/Logo';

const Navigation = () => (
    <AppBar
        position="sticky"
        sx={{
            pointerEvents: 'none',
            background: 'transparent',
            boxShadow: 'none',
        }}
    >
        <Toolbar sx={{ justifyContent: 'space-between', margin: '32px' }}>
            <Logo />
            <NavigationAccount />
        </Toolbar>
    </AppBar>
);

export default Navigation;
