import React, { useContext, useEffect, useState } from 'react';
import { FormControl, FormLabel, Box, Button, Typography } from '@mui/material';
import {
    FlowNode,
    getNodeUnitText,
    NodeTokenValue,
    NodeType,
    SemanticNodeData,
    SemanticNodeType,
} from '@prbx/types/Nodes';
import DeleteNode from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/DeleteNode';
import NodeNameTextField from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/NodeNameTextField';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import { usePrevious } from '@prbx/utils/usePrevious';
import { Edge, getIncomers, useReactFlow } from 'reactflow';
import useToken from '@prbx/utils/useToken';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import getFormattedNodeName from '@prbx/utils/getFormattedNodeName';

type SemanticNodeParentDisplayProps = {
    parentNode: FlowNode;
    parentToken: NodeTokenValue;
};

const SemanticNodeParentDisplay = ({
    parentNode,
    parentToken,
}: SemanticNodeParentDisplayProps) => {
    const { prefix } = useContext(WorkspaceContext);
    const parentName = getFormattedNodeName(
        prefix,
        parentNode.type as NodeType,
        parentNode.data.name
    );

    return (
        <FormControl>
            <FormLabel
                sx={{ mb: 1 }}
                data-testid="semanticnodeparentdisplay-parent"
            >
                Properties (from {parentName})
            </FormLabel>
            <Typography data-testid="semanticnodeparentdisplay-properties">
                <b>{getNodeUnitText(parentToken.unit)}:</b> {parentToken.value}
            </Typography>
        </FormControl>
    );
};

type SemanticNodeEditorProps = {
    node: SemanticNodeType;
};

const SemanticNodeEditor = ({ node }: SemanticNodeEditorProps) => {
    const { setNodes } = useReactFlow();
    const { sNodes: nodes, sEdges: edges } = useContext(FlowContext);

    const { id, data, type } = node;
    const { name } = data;
    const parent = getIncomers(node, nodes as FlowNode[], edges as Edge[]).find(
        (nd) => nd
    );
    const { token } = useToken(id);

    const [semanticName, setSemanticName] = useState<string>(name);
    const [nameError, setNameError] = useState<boolean>(false);
    const isUnsavedChanges = semanticName !== name;
    const canUpdateNode = isUnsavedChanges && !nameError;

    const prevNode = usePrevious(node);
    useEffect(() => {
        if (prevNode && (prevNode as SemanticNodeType).id !== id) {
            setSemanticName(name);
        }
    }, [node]);

    const handleUpdate = async () => {
        const newSemanticNodeData: SemanticNodeData = {
            ...data,
            name: semanticName,
        };

        setNodes((existingNodes) =>
            existingNodes.map((existingNode) => {
                if (existingNode.id === id) {
                    existingNode.data = newSemanticNodeData;
                }
                return existingNode;
            })
        );
    };

    return (
        <>
            <FormControl>
                <FormLabel sx={{ mb: 1 }}>Token Name</FormLabel>
                <NodeNameTextField
                    enablePrefix
                    node={node}
                    error={nameError}
                    state={semanticName}
                    setState={setSemanticName}
                    setErrorState={setNameError}
                />
            </FormControl>
            {parent && token && (
                <SemanticNodeParentDisplay
                    parentNode={parent}
                    parentToken={token}
                />
            )}
            <Box sx={{ pt: 2 }}>
                <DeleteNode id={id} type={type as NodeType} />
            </Box>
            <Box sx={{ alignSelf: 'flex-end', pt: '16px' }}>
                <Button
                    variant="contained"
                    disabled={!canUpdateNode}
                    onClick={handleUpdate}
                    data-testid="semanticnodeeditor-update"
                >
                    Update
                </Button>
            </Box>
        </>
    );
};

export default SemanticNodeEditor;
