import React from 'react';
import { NodeType } from '@prbx/types/Nodes';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { ReactComponent as ComponentToken } from '@prbx/component-token.svg';
import { ReactComponent as CoreToken } from '@prbx/core-token.svg';
import { ReactComponent as Component } from '@prbx/component.svg';
import { ReactComponent as SemanticToken } from '@prbx/semantic-token.svg';
import { isToken } from '@prbx/utils/node';

type NodeIconProps = SvgIconProps & {
    type: NodeType;
};

const NodeIcon = ({ type, ...props }: NodeIconProps) => {
    const theme = useTheme();
    const nodeIsToken = isToken(type);

    return (
        <SvgIcon
            {...props}
            sx={{
                ...props.sx,
                fill: '#757575',
                ...(!nodeIsToken && {
                    fill: 'none',
                    stroke: `${theme.palette.primary.main}`,
                    strokeWidth: 2,
                }),
            }}
        >
            {type === 'core-token' && <CoreToken />}
            {type === 'semantic-token' && <SemanticToken />}
            {type === 'component-token' && <ComponentToken />}
            {type === 'component' && <Component />}
        </SvgIcon>
    );
};

export default NodeIcon;
