import { Button, ButtonProps, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactNode, useState } from 'react';

type ConfirmationButtonProps = ButtonProps & {
    children: ReactNode;
    actionText: string;
    onConfirm: () => void;
};

const ConfirmationButton = ({
    children,
    actionText,
    onConfirm,
    ...buttonProps
}: ConfirmationButtonProps) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const handleConfirm = () => {
        onConfirm();
        setIsConfirming(false);
    };

    return (
        <>
            {isConfirming ? (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '0 8px',
                        }}
                    >
                        <Typography sx={{ mr: 1 }}>
                            Are you sure you want to {actionText}?
                        </Typography>
                        <Button onClick={() => setIsConfirming(false)}>
                            No
                        </Button>
                        <Button
                            color="error"
                            onClick={handleConfirm}
                            data-testid="confirmationbutton-yes"
                        >
                            Yes
                        </Button>
                    </Box>
                </>
            ) : (
                <Button
                    color="error"
                    variant="outlined"
                    onClick={() => setIsConfirming(true)}
                    {...buttonProps}
                    data-testid="confirmationbutton-delete"
                >
                    {children}
                </Button>
            )}
        </>
    );
};

export default ConfirmationButton;
