import { getNodeTypeText, NodeType } from '@prbx/types/Nodes';
import { isToken } from '@prbx/utils/node';

const getFormattedNodeName = (prefix: string, type: NodeType, name: string) => {
    if (isToken(type)) {
        return `--${prefix}-${getNodeTypeText(type)}-${name}`;
    } else {
        return name;
    }
};

export default getFormattedNodeName;
