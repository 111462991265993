export const muiTooltip = {
    styleOverrides: {
        tooltip: ({ theme }) => ({
            // fontSize: 16,
            // backgroundColor: theme.palette.foreground.tertiary,
            color: theme.palette.common.white,
            // padding: '0px 8px 0px 8px',
            borderRadius: '16px',
            fontWeight: 500,
        }),
    },
};
