import React, { SVGProps, useMemo } from 'react';
import { useTheme } from '@mui/material';
import Color from 'color';

import { generateShapeColor, getContrastBorder } from '@prbx/utils/color';
import { NodeTokenValue } from '@prbx/types/Nodes';

type CoreTokenShapeProps = {
    overrideShapeColor?: string;
    token?: NodeTokenValue;
    svgProps?: SVGProps<SVGSVGElement>;
    pathProps?: SVGProps<SVGPathElement>;
};

const CoreTokenShape = ({
    overrideShapeColor,
    token,
    svgProps,
    pathProps,
}: CoreTokenShapeProps) => {
    const theme = useTheme();

    const shapeColor = useMemo(
        () => overrideShapeColor ?? generateShapeColor(theme, token),
        [overrideShapeColor, theme, token]
    );

    const needsBorder = useMemo(
        () =>
            Color(shapeColor).contrast(
                Color(theme.palette.background.default)
            ) < 1.5,
        [shapeColor, theme.palette.background.default]
    );

    const contrastBorder = useMemo(
        () => getContrastBorder(shapeColor),
        [shapeColor]
    );

    const svgPropsToApply = {
        fill: shapeColor,
        ...svgProps,
        ...(needsBorder && {
            stroke: contrastBorder,
            strokeWidth: '4px',
        }),
    };

    return (
        <svg
            {...svgPropsToApply}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 163.87 142.79"
        >
            <clipPath id="coreIconClip">
                <path
                    {...pathProps}
                    d="m76.75,2.99L.82,133.78c-2.32,4,.56,9.01,5.19,9.01h151.85c4.63,0,7.51-5.01,5.19-9.01L87.12,2.99c-2.31-3.98-8.07-3.98-10.38,0Z"
                />
            </clipPath>
            <path
                {...pathProps}
                clipPath="url(#coreIconClip)"
                d="m76.75,2.99L.82,133.78c-2.32,4,.56,9.01,5.19,9.01h151.85c4.63,0,7.51-5.01,5.19-9.01L87.12,2.99c-2.31-3.98-8.07-3.98-10.38,0Z"
            />
        </svg>
    );
};

export default CoreTokenShape;
