import React, {
    createContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactFlow, {
    Background,
    useNodesState,
    useEdgesState,
    Connection,
    Edge,
    Node,
    ReactFlowInstance,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Box, useTheme } from '@mui/material';
import { User } from 'firebase/auth';
import { isEqual } from 'lodash';

import { StyledControls } from '@prbx/components/FlowEditor/StyledControls';
import FlowMiniMap, {
    MiniMapNode,
} from '@prbx/components/FlowEditor/FlowMiniMap';
import Logo from '@prbx/components/Navigation/Logo';
import { StyledPanel } from '@prbx/components/FlowEditor/StyledPanel';
import Header from '@prbx/components/FlowEditor/Header';
import RightPanel from '@prbx/components/FlowEditor/RightPanel';
import {
    debouncedSyncEdgesWithClient,
    debouncedSyncEdgesWithServer,
    debouncedSyncNodesWithClient,
    debouncedSyncNodesWithServer,
} from '@prbx/firebase/nodes';
import AddNodeControl from '@prbx/components/FlowEditor/AddNodeControl/AddNodeControl';
import { FlowNode, NodeData } from '@prbx/types/Nodes';
import ComponentNode from '@prbx/components/Nodes/ComponentNode';
import AddComponentPropertyDialog from '@prbx/components/FlowEditor/AddComponentPropertyDialog';
import CoreToken from '@prbx/components/Nodes/CoreToken';
import SemanticToken from '@prbx/components/Nodes/SemanticToken';
import ElementEditor from '@prbx/components/FlowEditor/ElementEditor/ElementEditor';
import { EdgeData } from '@prbx/types/Edges';
import { addPropertyEdge, handleEdgeConnect } from '@prbx/utils/edge';

export type FlowContextType = {
    sNodes: {
        type: string | undefined;
        id: string;
        data: NodeData;
        selected: boolean | undefined;
    }[];
    sEdges: {
        id: string;
        source: string;
        target: string;
        selected: boolean | undefined;
    }[];
    setNodes: React.Dispatch<
        React.SetStateAction<Node<NodeData, string | undefined>[]>
    >;
    setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
};

export const FlowContext = createContext<FlowContextType>(
    {} as FlowContextType
);

const nodeTypes = {
    'core-token': CoreToken,
    'semantic-token': SemanticToken,
    'component-token': SemanticToken,
    component: ComponentNode,
};

type FlowEditorProps = {
    id: string;
    user: User;
    serverNodeData: { nodes: FlowNode[]; version: number };
    serverEdgeData: { edges: Edge[]; version: number };
};

const FlowEditor = ({
    id,
    user,
    serverNodeData,
    serverEdgeData,
}: FlowEditorProps) => {
    const theme = useTheme();

    // ReactFlow wrapper and instance state
    const [reactFlowInstance, setReactFlowInstance] =
        useState<ReactFlowInstance>();
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    // --------

    // Server-side (from props)
    const { nodes: serverNodes, version: serverNodesVersion } = useMemo(
        () => serverNodeData,
        [serverNodeData]
    );
    const { edges: serverEdges, version: serverEdgesVersion } = useMemo(
        () => serverEdgeData,
        [serverNodeData]
    );
    // --------

    // Client-side state
    const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>(
        serverNodes ?? []
    );
    const [nodesVersion, setNodesVersion] =
        useState<number>(serverNodesVersion);
    const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeData>(
        serverEdges ?? []
    );
    const [edgesVersion, setEdgesVersion] =
        useState<number>(serverEdgesVersion);
    // --------

    // Client-side change -> server-side change
    useEffect(() => {
        debouncedSyncNodesWithServer(
            id,
            user,
            { nodes, version: nodesVersion },
            serverNodeData,
            setNodesVersion
        );
    }, [nodes]);
    useEffect(() => {
        debouncedSyncEdgesWithServer(
            id,
            user,
            { edges, version: edgesVersion },
            serverEdgeData,
            setEdgesVersion
        );
    }, [edges]);
    // --------

    // Server-side change -> client-side change
    useEffect(() => {
        debouncedSyncNodesWithClient(
            { nodes, version: nodesVersion },
            serverNodeData,
            setNodes,
            setNodesVersion
        );
    }, [serverNodes]);
    useEffect(() => {
        debouncedSyncEdgesWithClient(
            { edges, version: edgesVersion },
            serverEdgeData,
            setEdges,
            setEdgesVersion
        );
    }, [serverEdges]);
    // --------

    // Edge handling functions
    const [addPropertyDialogOpen, setAddPropertyDialogOpen] =
        useState<boolean>(false);
    const [addPropertyParams, setAddPropertyParams] = useState<Connection>();

    const onConnect = (params: Edge<EdgeData> | Connection) => {
        if (reactFlowInstance) {
            handleEdgeConnect(
                params,
                reactFlowInstance,
                setAddPropertyDialogOpen,
                setAddPropertyParams,
                theme
            );
        }
    };
    // --------

    // Optimised nodes/edges for event handling
    const [sNodes, setSNodes] = useState(
        nodes.map(({ type, id, data, selected }) => ({
            type,
            id,
            data,
            selected,
        }))
    );
    const [sEdges, setSEdges] = useState(
        edges.map(({ id, source, target, data, selected }) => ({
            id,
            source,
            target,
            data,
            selected,
        }))
    );
    useEffect(() => {
        const newSNodes = nodes.map(({ type, id, data, selected }) => ({
            type,
            id,
            data,
            selected,
        }));

        if (!isEqual(sNodes, newSNodes)) {
            setSNodes(newSNodes);
        }
    }, [nodes]);
    useEffect(() => {
        const newSEdges = edges.map(
            ({ id, source, target, data, selected }) => ({
                id,
                source,
                target,
                data,
                selected,
            })
        );

        if (!isEqual(sEdges, newSEdges)) {
            setSEdges(newSEdges);
        }
    }, [edges]);
    // --------

    const flowContextValue = useMemo(() => {
        return {
            sNodes: sNodes,
            sEdges: sEdges,
            setNodes: setNodes,
            setEdges: setEdges,
        };
    }, [sNodes, sEdges, setNodes, setEdges]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
            }}
            ref={reactFlowWrapper}
        >
            <FlowContext.Provider value={flowContextValue}>
                <ReactFlow
                    nodeTypes={nodeTypes}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={(instance) => setReactFlowInstance(instance)}
                    proOptions={{ hideAttribution: true }}
                    fitView
                >
                    <StyledPanel position="top-left">
                        <Box
                            sx={{
                                minHeight: '64px',
                                display: 'flex',
                                alignItems: 'center',
                                zIndex: '100',
                            }}
                        >
                            <Logo />
                        </Box>
                        <ElementEditor />
                    </StyledPanel>

                    <StyledPanel position="top-center">
                        <Header id={id} user={user} />
                    </StyledPanel>

                    <StyledPanel position="top-right">
                        <RightPanel id={id} user={user} />
                    </StyledPanel>

                    <StyledPanel position="bottom-left">
                        <StyledControls />
                    </StyledPanel>

                    <StyledPanel position="bottom-center">
                        <AddNodeControl flowWrapper={reactFlowWrapper} />
                    </StyledPanel>

                    <StyledPanel position="bottom-right">
                        <FlowMiniMap
                            nodeComponent={MiniMapNode}
                            zoomable
                            pannable
                        />
                    </StyledPanel>

                    <Background />

                    {addPropertyParams && (
                        <AddComponentPropertyDialog
                            open={addPropertyDialogOpen}
                            close={() => {
                                setAddPropertyDialogOpen(false);
                                setAddPropertyParams(undefined);
                            }}
                            params={addPropertyParams}
                            onEnterPropertyName={(properties: string[]) => {
                                if (reactFlowInstance) {
                                    addPropertyEdge(
                                        properties,
                                        addPropertyParams,
                                        reactFlowInstance,
                                        theme
                                    );
                                }
                            }}
                        />
                    )}
                </ReactFlow>
            </FlowContext.Provider>
        </Box>
    );
};

export default FlowEditor;
