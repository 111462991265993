import React from 'react';
import { PaletteMode, CssBaseline, ThemeProvider } from '@mui/material';
import { createPrbxTheme } from '@prbx/theme/prbxTheme';
import { useLocalStorage } from '@prbx/utils/useLocalStorage';
import { Toaster } from 'react-hot-toast';

type ThemingProviderProps = {
    children: string | JSX.Element | JSX.Element[];
};

export const ColorModeContext = React.createContext({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleColorMode: () => {},
});

const ThemingProvider = ({ children }: ThemingProviderProps) => {
    const [mode, setMode] = useLocalStorage('mode', 'light');

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode(mode == 'light' ? 'dark' : 'light');
            },
        }),
        [mode]
    );

    const theme = React.useMemo(
        () => createPrbxTheme(mode as PaletteMode),
        [mode]
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Toaster
                    toastOptions={{
                        style: {
                            border: `2px solid ${theme.palette.divider}`,
                            background: theme.palette.background.paper,
                            color: theme.palette.text.primary,
                        },
                        error: {
                            iconTheme: {
                                primary: theme.palette.error.main,
                                secondary: theme.palette.background.paper,
                            },
                        },
                        success: {
                            iconTheme: {
                                primary: theme.palette.success.main,
                                secondary: theme.palette.background.paper,
                            },
                        },
                        position: 'bottom-center',
                    }}
                />
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default ThemingProvider;
