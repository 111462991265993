import React, { memo, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { NodeProps, Position } from 'reactflow';
import Color from 'color';

import { ComponentNodeData } from '@prbx/types/Nodes';
import { getContrastBorder } from '@prbx/utils/color';
import useComponent from '@prbx/utils/useComponent';
import { StyledHandle } from '@prbx/components/Nodes/StyledHandle';
import { ErrorBoundary } from 'react-error-boundary';

const ComponentNode = ({ id, data, type }: NodeProps<ComponentNodeData>) => {
    const theme = useTheme();
    const { name, canvasColor, childText } = data;
    const { component: RenderComponent, styles: RenderStyles } =
        useComponent(id);

    const backgroundColor = useMemo(() => {
        if (canvasColor.enabled) {
            return Color(canvasColor.color).hex();
        } else {
            return theme.palette.background.default;
        }
    }, [canvasColor, theme.palette.background.default]);

    const needsBorder = useMemo(
        () =>
            Color(backgroundColor).contrast(
                Color(theme.palette.background.default)
            ) < 1.5,
        [backgroundColor, theme.palette.background.default]
    );

    const contrastBorder = useMemo(() => {
        if (needsBorder && canvasColor.enabled) {
            return getContrastBorder(backgroundColor);
        } else {
            return theme.palette.primary.main;
        }
    }, [backgroundColor, theme, needsBorder]);

    return (
        <>
            <StyledHandle
                type="target"
                position={Position.Top}
                id={`${type}-target`}
            />
            <Box
                className="node-draggable"
                sx={{
                    ...(needsBorder && {
                        border: `2px solid ${contrastBorder}`,
                    }),
                    borderRadius: '8px',
                    padding: '8px 16px',
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    background: backgroundColor,
                    color: theme.palette.getContrastText(backgroundColor),
                }}
            >
                <Typography data-testid="componentnode-name">{name}</Typography>
                <Box
                    sx={{
                        border: '2px solid transparent',
                        '&:hover': {
                            border: `2px dashed ${theme.palette.getContrastText(
                                backgroundColor
                            )}`,
                        },
                    }}
                    data-testid="componentnode-component"
                >
                    {RenderComponent ? (
                        <ErrorBoundary
                            fallback={<RenderComponent style={RenderStyles} />}
                        >
                            {childText.enabled ? (
                                <RenderComponent style={RenderStyles}>
                                    {childText.text}
                                </RenderComponent>
                            ) : (
                                <RenderComponent style={RenderStyles} />
                            )}
                        </ErrorBoundary>
                    ) : (
                        <Typography>No component found.</Typography>
                    )}
                </Box>
            </Box>
            <StyledHandle
                type="source"
                position={Position.Bottom}
                id={`${type}-source`}
            />
        </>
    );
};

export default memo(ComponentNode);
