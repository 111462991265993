import { styled } from '@mui/material';
import { Controls } from 'reactflow';

export const StyledControls = styled(Controls)`
    &.react-flow__controls {
        background: ${(props) => props.theme.palette.background.default};
        border: 2px solid ${(props) => props.theme.palette.divider};
        border-radius: 8px;
        overflow: hidden;
        box-shadow: ${(props) => props.theme.shadows};
        margin: 0;
    }

    button {
        background: ${(props) => props.theme.palette.background.paper};
        color: ${(props) => props.theme.palette.text.primary};
        border-bottom: 1px solid ${(props) => props.theme.palette.divider};
        width: 48px;
        height: 48px;

        &:hover {
            background-color: ${(props) => props.theme.palette.action.hover};
        }

        &:active {
            background-color: ${(props) => props.theme.palette.action.selected};
        }

        path {
            fill: currentcolor;
        }

        svg {
            max-width: unset;
            max-height: unset;
            width: 16px;
            height: 16px;
        }
    }
`;
