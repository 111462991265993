import { CoreNodeType, ComponentNodeType, FlowNode } from '@prbx/types/Nodes';
import getAllIncomers from '@prbx/utils/getAllIncomers';
import { Edge, getConnectedEdges } from 'reactflow';

export const getUpstreamComponent = (
    node: ComponentNodeType,
    connectedEdges: Edge[],
    getNode,
    sNodes: FlowNode[],
    sEdges: Edge[]
): React.ComponentType<React.HTMLAttributes<HTMLElement>> => {
    let componentToReturn = node.data
        .component as unknown as React.ComponentType<
        React.HTMLAttributes<HTMLElement>
    >;
    connectedEdges.forEach((edge) => {
        const sourceNode = getNode(edge.source) as FlowNode;
        if (sourceNode.type === 'component') {
            const sourceConnectedEdges = getConnectedEdges(
                [sourceNode],
                sEdges as Edge[]
            ).filter((edge) => edge.target === sourceNode.id);
            componentToReturn = getUpstreamComponent(
                sourceNode as ComponentNodeType,
                sourceConnectedEdges,
                getNode,
                sNodes,
                sEdges
            );
        }
    });

    return componentToReturn;
};

export const getUpstreamCoreToken = (
    node: FlowNode,
    nodes: FlowNode[],
    edges: Edge[]
): CoreNodeType | undefined => {
    const newIncomers = getAllIncomers(node, nodes, edges);
    const coreNode = newIncomers.find(
        ({ type }) => type === 'core-token'
    ) as CoreNodeType;

    if (coreNode) {
        return coreNode;
    } else {
        return undefined;
    }
};
