import React from 'react';
import { Box, Typography } from '@mui/material';
import { db } from '@prbx/firebase/firebase';
import { ref } from 'firebase/database';
import { useObject } from 'react-firebase-hooks/database';
import ConnectedUser from '@prbx/components/Editor/ConnectedUserManager/ConnectedUser';
import { User } from 'firebase/auth';

type ConnectedUserListProps = {
    id: string;
    user: User;
};

const ConnectedUserList = ({ id, user }: ConnectedUserListProps) => {
    const [connectedUsers, connectedUsersLoading] = useObject(
        ref(db, `/workspaces/${id}/connectedUsers`)
    );

    if (connectedUsersLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (connectedUsers && connectedUsers.val()) {
        const connectedUsersList = Object.keys(connectedUsers.val());
        delete connectedUsersList[connectedUsersList.indexOf(user.uid)];

        if (connectedUsersList.length > 0) {
            return (
                <Box sx={{ display: 'flex' }}>
                    {connectedUsersList.map((key) => {
                        return <ConnectedUser uid={key} key={key} />;
                    })}
                </Box>
            );
        }
    }
    return <></>;
};

export default ConnectedUserList;
