import React from 'react';
import { useMediaQuery, Grid, useTheme, Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import hero from '@prbx/hero.png';
import SignIn from '@prbx/components/Authentication/SignIn';
import { ROUTES } from '@prbx/consts';
import ForgotPassword from '@prbx/components/Authentication/ForgotPassword';
import Dashboard from '@prbx/components/Dashboard/Dashboard';
import Navigation from '@prbx/components/Navigation/Navigation';
import Register from '@prbx/components/Authentication/Register';
import VerifyEmail from '@prbx/components/Authentication/VerifyEmail';

type HomeProps = {
    userVerified: boolean;
};

const Home = ({ userVerified }: HomeProps) => {
    const theme = useTheme();
    const splitView = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            <Navigation />
            <Grid
                container
                sx={{
                    mt: '-128px',
                    height: '100vh',
                }}
            >
                {splitView && (
                    <Grid
                        item
                        lg={4}
                        md={3}
                        xs={0}
                        component="img"
                        sx={{
                            objectFit: 'cover',
                            mixBlendMode: 'luminosity',
                            minHeight: '100vh',
                            top: 0,
                        }}
                        alt="leaves"
                        src={hero}
                    />
                )}
                <Grid
                    item
                    lg={8}
                    md={9}
                    xs={12}
                    sx={{
                        maxHeight: '100vh',
                        display: 'flex',
                        overflow: 'auto',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            background: theme.palette.background.default,
                            minHeight: '128px',
                            position: 'sticky',
                            top: 0,
                            zIndex: 2,
                        }}
                    ></Box>
                    <Box
                        sx={{
                            flex: '1 0',
                            zIndex: 1,
                            position: 'relative',
                        }}
                    >
                        <Routes>
                            {userVerified && (
                                <Route
                                    path={ROUTES.DASHBOARD}
                                    element={<Dashboard />}
                                />
                            )}
                            <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
                            <Route
                                path={ROUTES.REGISTER}
                                element={<Register />}
                            />
                            <Route
                                path={ROUTES.FORGOT_PASSWORD}
                                element={<ForgotPassword />}
                            />
                            <Route
                                path={ROUTES.VERIFY_EMAIL}
                                element={<VerifyEmail />}
                            />
                        </Routes>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Home;
