import { CoreNodeType, FlowNode, NodeTokenValue } from '@prbx/types/Nodes';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Edge, useReactFlow } from 'reactflow';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import { getUpstreamCoreToken } from '@prbx/utils/upstreamAttributes';

const useToken = (
    id: string
): { token: NodeTokenValue | undefined; parent: CoreNodeType | undefined } => {
    const { getNode } = useReactFlow();
    const node = useMemo(() => getNode(id), [id]);

    const { sNodes, sEdges } = useContext(FlowContext);
    const [token, setToken] = useState<NodeTokenValue | undefined>();
    const [parent, setParent] = useState<CoreNodeType>();

    useEffect(() => {
        if (node) {
            let coreToken;
            if (node.type === 'core-token') {
                coreToken = node;
            } else {
                coreToken = getUpstreamCoreToken(
                    node as FlowNode,
                    sNodes as FlowNode[],
                    sEdges as Edge[]
                );
            }
            if (coreToken) {
                setParent(coreToken);
                setToken(coreToken.data.token);
            } else {
                setParent(undefined);
                setToken(undefined);
            }
        }
    }, [sNodes, sEdges, node]);

    if (node && node.type === 'core-token') {
        const coreNode = node as CoreNodeType;
        return { token: coreNode.data.token, parent: coreNode };
    }

    return { token, parent };
};

export default useToken;
