import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    FormControl,
    FormLabel,
    Box,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Autocomplete,
    Typography,
} from '@mui/material';
import {
    ComponentNodeData,
    ComponentNodeType,
    NodeType,
} from '@prbx/types/Nodes';
import DeleteNode from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/DeleteNode';
import { usePrevious } from '@prbx/utils/usePrevious';
import { ERROR_UPDATE_COMPONENT, htmlTagNames } from '@prbx/consts';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Color from 'color';
import ComponentNameTextField from '@prbx/components/FlowEditor/ElementEditor/NodeEditor/ComponentNameTextField';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import useComponent from '@prbx/utils/useComponent';
import { isValidColor } from '@prbx/utils/node';

type ComponentNodeEditorProps = {
    node: ComponentNodeType;
};

const ComponentNodeEditor = ({ node }: ComponentNodeEditorProps) => {
    const { setNodes } = useContext(FlowContext);
    const { id, data, type } = node;
    const { inheritingFrom, component: InheritedComponent } = useComponent(id);
    const { name, component, childText, canvasColor } = data;

    // Component name
    const [componentName, setComponentName] = useState<string>(name);
    const [nameError, setNameError] = useState<boolean>(false);
    // Component tag
    const [componentTag, setComponentTag] = useState<string>(component);
    // Component text
    const [componentTextEnabled, setComponentTextEnabled] = useState<boolean>(
        childText.enabled
    );
    const [componentText, setComponentText] = useState<string>(childText.text);
    const componentTextError = componentText.length === 0;
    // Component canvas color
    const [componentCanvasColorEnabled, setComponentCanvasColorEnabled] =
        useState<boolean>(canvasColor.enabled);
    const [componentCanvasColor, setComponentCanvasColor] = useState<string>(
        canvasColor.color
    );
    const componentCanvasColorError = useMemo(
        () => !isValidColor(componentCanvasColor),
        [componentCanvasColor]
    );

    const isUnsavedChanges = useMemo(
        () =>
            componentName !== name ||
            componentTag !== component ||
            componentCanvasColor !== canvasColor.color ||
            componentCanvasColorEnabled !== canvasColor.enabled ||
            componentTextEnabled !== childText.enabled ||
            componentText !== childText.text,
        [
            data,
            componentName,
            componentTag,
            componentTextEnabled,
            componentText,
            componentCanvasColorEnabled,
            componentCanvasColor,
        ]
    );

    const canUpdateNode = useMemo(
        () =>
            isUnsavedChanges &&
            !nameError &&
            !componentCanvasColorError &&
            !componentTextError,
        [
            isUnsavedChanges,
            nameError,
            componentCanvasColorError,
            componentTextError,
        ]
    );

    const prevNode = usePrevious(node);
    useEffect(() => {
        if (prevNode && (prevNode as ComponentNodeType).id !== id) {
            setComponentName(name);
            setComponentTag(component);
            setComponentTextEnabled(childText.enabled);
            setComponentText(childText.text);
            setComponentCanvasColor(canvasColor.color);
            setComponentCanvasColorEnabled(canvasColor.enabled);
        }
    }, [node]);

    const handleUpdate = () => {
        const newComponentNodeData: ComponentNodeData = {
            ...data,
            component: componentTag,
            name: componentName,
            childText: {
                enabled: componentTextEnabled,
                text: componentText,
            },
            canvasColor: {
                enabled: componentCanvasColorEnabled,
                color: componentCanvasColor,
            },
        };

        setNodes((existingNodes) =>
            existingNodes.map((existingNode) => {
                if (existingNode.id === id) {
                    existingNode.data = newComponentNodeData;
                }
                return existingNode;
            })
        );
    };

    return (
        <>
            <FormControl>
                <FormLabel sx={{ mb: 1 }}>Component Name</FormLabel>
                <ComponentNameTextField
                    node={node}
                    state={componentName}
                    error={nameError}
                    setState={setComponentName}
                    setErrorState={setNameError}
                />
            </FormControl>
            <FormControl>
                <FormLabel sx={{ mb: 1 }}>Component Tag</FormLabel>
                {inheritingFrom && InheritedComponent ? (
                    <Typography data-testid="componentnodeeditor-inheritingfrom">
                        Inheriting <b>{InheritedComponent.toString()}</b> from{' '}
                        {inheritingFrom.data.name}
                    </Typography>
                ) : (
                    <Autocomplete
                        value={componentTag}
                        onChange={(event, newValue: string | null) => {
                            if (newValue) {
                                setComponentTag(newValue);
                            }
                        }}
                        options={htmlTagNames}
                        renderInput={(params) => (
                            <TextField
                                sx={{ '& .MuiInputBase-root': { pt: 0 } }}
                                variant="filled"
                                {...params}
                                data-testid="componentnodeeditor-tag-input"
                            />
                        )}
                    />
                )}
            </FormControl>
            <FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={componentTextEnabled}
                            onChange={() =>
                                setComponentTextEnabled(!componentTextEnabled)
                            }
                            data-testid="componentnodeeditor-childtext-checkbox"
                        />
                    }
                    label="Component child text (if applicable)"
                />
                <TextField
                    disabled={!componentTextEnabled}
                    variant="filled"
                    value={componentText}
                    onChange={(event) => setComponentText(event.target.value)}
                    error={isUnsavedChanges && componentTextError}
                    helperText={
                        componentTextError &&
                        isUnsavedChanges &&
                        ERROR_UPDATE_COMPONENT.INVALID_CHILD_TEXT
                    }
                    data-testid="componentnodeeditor-childtext-textfield"
                />
            </FormControl>
            <FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={componentCanvasColorEnabled}
                            onChange={() =>
                                setComponentCanvasColorEnabled(
                                    !componentCanvasColorEnabled
                                )
                            }
                            data-testid="componentnodeeditor-canvascolor-checkbox"
                        />
                    }
                    label="Custom canvas color"
                />
                <TextField
                    disabled={!componentCanvasColorEnabled}
                    variant="filled"
                    value={componentCanvasColor}
                    onChange={(event) =>
                        setComponentCanvasColor(event.target.value)
                    }
                    error={isUnsavedChanges && componentCanvasColorError}
                    helperText={
                        componentCanvasColorError &&
                        isUnsavedChanges &&
                        ERROR_UPDATE_COMPONENT.INVALID_CANVAS_COLOR
                    }
                    InputProps={{
                        ...(isUnsavedChanges && componentCanvasColorError
                            ? {
                                  endAdornment: (
                                      <ErrorRoundedIcon color="error" />
                                  ),
                              }
                            : {
                                  endAdornment: (
                                      <Box
                                          component="input"
                                          className="nodrag"
                                          type="color"
                                          onChange={(event) =>
                                              setComponentCanvasColor(
                                                  event.target.value
                                              )
                                          }
                                          value={Color(
                                              componentCanvasColor
                                          ).hex()}
                                          sx={{
                                              appearance: 'none',
                                              width: '32px',
                                              height: '32px',
                                              backgroundColor: 'transparent',
                                              border: 'none',
                                              cursor: 'pointer',
                                              '&::-webkit-color-swatch': {
                                                  borderRadius: '2px',
                                                  border: 'none',
                                              },
                                          }}
                                      />
                                  ),
                              }),
                    }}
                    InputLabelProps={{ disabled: true }}
                    data-testid="componentnodeeditor-canvascolor-textfield"
                />
            </FormControl>
            <Box sx={{ pt: 2 }}>
                <DeleteNode id={id} type={type as NodeType} />
            </Box>
            <Box sx={{ alignSelf: 'flex-end', pt: '16px' }}>
                <Button
                    variant="contained"
                    disabled={!canUpdateNode}
                    onClick={handleUpdate}
                    data-testid="componentnodeeditor-update"
                >
                    Update
                </Button>
            </Box>
        </>
    );
};

export default ComponentNodeEditor;
