import { Node } from 'reactflow';

// Node types
export const nodeTypes = [
    'core-token',
    'semantic-token',
    'component-token',
    'component',
] as const;
export type NodeType = (typeof nodeTypes)[number];
enum NodeTypetext {
    'core-token' = 'core',
    'semantic-token' = 'semantic',
    'component-token' = 'component',
    'component' = 'component',
}
export const getNodeTypeText = (key: NodeType): string => {
    return NodeTypetext[key as keyof typeof NodeTypetext];
};

// Node token units
export type NodeUnit = 'color' | 'other';
enum NodeUnitText {
    color = 'Colour',
    other = 'Other',
}
export const getNodeUnitText = (key: NodeUnit): string => {
    return NodeUnitText[key as keyof typeof NodeUnitText];
};

// Node tokens
export type NodeTokenValue =
    | {
          unit: 'other';
          value: string;
      }
    | {
          unit: 'color';
          value: string;
      };

// Token type
export type DefaultNodeData = {
    name: string;
};

// Core node
export type CoreNodeData = DefaultNodeData & {
    token: NodeTokenValue;
};
export type CoreNodeType = Node<CoreNodeData>;

// Semantic node
export type SemanticNodeData = DefaultNodeData;
export type SemanticNodeType = Node<SemanticNodeData>;

// Component node
export type ComponentNodeData = DefaultNodeData & {
    component: string;
    childText: {
        enabled: boolean;
        text: string;
    };
    canvasColor: {
        enabled: boolean;
        color: string;
    };
};
export type ComponentNodeType = Node<ComponentNodeData>;

// Node data
export type NodeData = CoreNodeData | SemanticNodeData | ComponentNodeData;

// Node
export type FlowNode = Node<NodeData>;
