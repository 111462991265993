import { styled } from '@mui/material';
import { Panel } from 'reactflow';

export const StyledPanel = styled(Panel)`
    margin: 32px 56px;

    &.react-flow__panel.center {
        margin: 32px 0;
    }
`;
