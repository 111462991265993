import React, { useEffect } from 'react';
import { db } from '@prbx/firebase/firebase';
import { User } from 'firebase/auth';
import { ref, onDisconnect } from 'firebase/database';
import { useObject } from 'react-firebase-hooks/database';
import {
    connectUserToWorkspace,
    disconnectUserFromWorkspace,
} from '@prbx/firebase/workspace';

type ConnectedUserManagerProps = {
    id: string;
    user: User;
    children: string | JSX.Element | JSX.Element[];
};

const ConnectedUserManager = ({
    id,
    user,
    children,
}: ConnectedUserManagerProps) => {
    // Remove user from list of connected users when forcefully leaving component
    onDisconnect(
        ref(db, `/workspaces/${id}/connectedUsers/${user.uid}`)
    ).remove();

    const [connectedUsers] = useObject(
        ref(db, `/workspaces/${id}/connectedUsers`)
    );

    useEffect(() => {
        // Remove user from list of connected users when leaving component
        return () => {
            disconnectUserFromWorkspace(id, user.uid);
        };
    }, []);

    useEffect(() => {
        // Add user to list of connected users if they are not present
        if (connectedUsers && connectedUsers.size > 0) {
            const connectedUsersList = Object.keys(connectedUsers);
            if (!connectedUsersList.includes(user.uid)) {
                connectUserToWorkspace(id, user.uid);
            }
        } else {
            connectUserToWorkspace(id, user.uid);
        }
    }, [user]);

    return <>{children}</>;
};

export default ConnectedUserManager;
