import React, { useContext, useMemo } from 'react';
import { styled } from '@mui/material';
import {
    Edge,
    MiniMap,
    MiniMapNodeProps,
    MiniMapProps,
    useReactFlow,
} from 'reactflow';
import {
    ComponentNodeData,
    CoreNodeType,
    FlowNode,
    NodeType,
} from '@prbx/types/Nodes';
import CoreTokenShape from '@prbx/components/Nodes/CoreTokenShape';
import SemanticTokenShape from '@prbx/components/Nodes/SemanticTokenShape';
import ComponentTokenShape from '@prbx/components/Nodes/ComponentTokenShape';
import ComponentNodeShape from '@prbx/components/Nodes/ComponentNodeShape';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import { getUpstreamCoreToken } from '@prbx/utils/upstreamAttributes';

export const MiniMapNode = ({ x, y, id, width, height }: MiniMapNodeProps) => {
    const { getNode } = useReactFlow();
    const { sNodes: nodes, sEdges: edges } = useContext(FlowContext);

    const node = useMemo(() => getNode(id), [id, nodes]);

    if (node) {
        const type = node.type as NodeType;
        if (type == 'core-token') {
            return (
                <CoreTokenShape
                    token={(node as CoreNodeType).data.token}
                    svgProps={{
                        x,
                        y,
                        height: '142',
                        width: '164',
                    }}
                />
            );
        }
        if (type == 'semantic-token') {
            const token = getUpstreamCoreToken(
                node,
                nodes as FlowNode[],
                edges as Edge[]
            )?.data.token;
            return (
                <SemanticTokenShape
                    token={token}
                    svgProps={{
                        x,
                        y,
                        height: '96',
                        width: '164',
                    }}
                />
            );
        }
        if (type == 'component-token') {
            const token = getUpstreamCoreToken(
                node,
                nodes as FlowNode[],
                edges as Edge[]
            )?.data.token;
            return (
                <ComponentTokenShape
                    token={token}
                    svgProps={{
                        x,
                        y,
                        height: '142',
                        width: '142',
                    }}
                />
            );
        }
        if (type == 'component') {
            return (
                <ComponentNodeShape
                    data={node.data as ComponentNodeData}
                    rectProps={{
                        rx: '8',
                        x,
                        y,
                        width,
                        height,
                    }}
                />
            );
        }
    }
    return <circle cx={50} cy={50} r="50" />;
};

const StyledMiniMap = styled(MiniMap)`
    background-color: ${(props) => props.theme.palette.background.default};

    .react-flow__minimap-mask {
        fill: ${(props) => props.theme.palette.divider};
    }

    &.react-flow__minimap {
        background: ${(props) => props.theme.palette.background.default};
        border: 2px solid ${(props) => props.theme.palette.divider};
        border-radius: 8px;
        overflow: hidden;
        box-shadow: ${(props) => props.theme.shadows};
        height: 238px;
        width: 300px;
        margin: 0;
    }

    svg {
        height: 238px;
        width: 296px;
        display: block;
    }
`;

type FlowMiniMap = MiniMapProps;

const FlowMiniMap = ({ ...flowMiniMapProps }) => {
    return <StyledMiniMap {...flowMiniMapProps} />;
};

export default FlowMiniMap;
