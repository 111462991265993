import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { Edge } from 'reactflow';
import { isEqual } from 'lodash';

import DeleteEdge from '@prbx/components/FlowEditor/ElementEditor/EdgeEditor/DeleteEdge';
import { FlowContext } from '@prbx/components/FlowEditor/FlowEditor';
import { CoreNodeData, FlowNode, NodeType } from '@prbx/types/Nodes';
import NodeChip from '@prbx/components/Nodes/NodeChip';
import getFormattedNodeName from '@prbx/utils/getFormattedNodeName';
import { WorkspaceContext } from '@prbx/components/Editor/Editor';
import EdgePropertiesMultiSelect from '@prbx/components/FlowEditor/ElementEditor/EdgeEditor/EdgePropertiesMultiSelect';
import { usePrevious } from '@prbx/utils/usePrevious';
import { EdgeData } from '@prbx/types/Edges';
import generatePropertiesLabel from '@prbx/utils/generatePropertiesLabel';
import { getUpstreamCoreToken } from '@prbx/utils/upstreamAttributes';
import { isToken } from '@prbx/utils/node';

type ApplicationEdgeEditorProps = {
    sourceNode: FlowNode;
    targetNode: FlowNode;
    selectedEdge: Edge;
};

const ApplicationEdgeEditor = ({
    sourceNode,
    targetNode,
    selectedEdge,
}: ApplicationEdgeEditorProps) => {
    const { id, data } = selectedEdge;
    const { prefix } = useContext(WorkspaceContext);
    const { sNodes: nodes, sEdges: edges, setEdges } = useContext(FlowContext);

    let value;
    let sourceName;

    const type = sourceNode.type as NodeType;
    if (type === 'core-token') {
        value = (sourceNode.data as CoreNodeData).token.value;
        sourceName = getFormattedNodeName(prefix, type, sourceNode.data.name);
    } else if (type === 'semantic-token' || type === 'component-token') {
        value = getUpstreamCoreToken(
            sourceNode,
            nodes as FlowNode[],
            edges as Edge[]
        )?.data.token.value;
        sourceName = getFormattedNodeName(prefix, type, sourceNode.data.name);
    }

    const [properties, setProperties] = useState<string[]>(
        data.properties ?? []
    );
    const [propertiesError, setPropertiesError] = useState<boolean>(false);
    const isUnsavedChanges = !isEqual(properties, selectedEdge.data.properties);
    const canUpdateEdge = useMemo(
        () => isUnsavedChanges && !propertiesError,
        [isUnsavedChanges, propertiesError]
    );

    const prevEdge = usePrevious(selectedEdge);
    useEffect(() => {
        if (prevEdge && (prevEdge as Edge).id !== id && data) {
            setProperties(data.properties);
        }
    }, [selectedEdge]);

    const handleUpdate = () => {
        const newEdgeData: EdgeData = {
            ...data,
            properties,
        };

        setEdges((existingEdges) =>
            existingEdges.map((existingEdge) => {
                if (existingEdge.id === id) {
                    existingEdge.data = newEdgeData;
                    existingEdge.label = generatePropertiesLabel(properties);
                }
                return existingEdge;
            })
        );
    };

    return (
        <>
            <Typography>
                Applying token <b>{sourceName}</b> to properties:
            </Typography>
            <EdgePropertiesMultiSelect
                initialProperties={data.properties}
                targetNode={targetNode as FlowNode}
                propertyValue={value}
                state={properties}
                error={isUnsavedChanges && propertiesError}
                setState={setProperties}
                setErrorState={setPropertiesError}
            />

            <Box sx={{ pt: 2 }}>
                <DeleteEdge id={id} />
            </Box>
            <Box sx={{ alignSelf: 'flex-end', pt: '16px' }}>
                <Button
                    variant="contained"
                    disabled={!canUpdateEdge}
                    onClick={handleUpdate}
                >
                    Update
                </Button>
            </Box>
        </>
    );
};

type EdgeEditorProps = {
    selectedEdge: Edge;
};

const EdgeEditor = ({ selectedEdge }: EdgeEditorProps) => {
    const { id, source, target, data } = selectedEdge;
    const { sNodes } = useContext(FlowContext);

    const sourceNode = sNodes.find((nd) => nd.id === source) as FlowNode;
    const targetNode = sNodes.find((nd) => nd.id === target) as FlowNode;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <RouteRoundedIcon />
                {isToken(sourceNode.type as NodeType) &&
                    targetNode.type === 'component' && (
                        <Typography ml={1} variant="h6">
                            Token application
                        </Typography>
                    )}
                {!isToken(sourceNode.type as NodeType) &&
                    targetNode.type === 'component' && (
                        <Typography ml={1} variant="h6">
                            Component application
                        </Typography>
                    )}
                {targetNode.type !== 'component' && (
                    <Typography ml={1} variant="h6">
                        Token definition
                    </Typography>
                )}
            </Box>
            <Box>
                <NodeChip node={sourceNode as FlowNode} />
                <br />
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <SubdirectoryArrowRightRoundedIcon />
                    <NodeChip node={targetNode as FlowNode} />
                </Box>
            </Box>
            {data ? (
                <ApplicationEdgeEditor
                    sourceNode={sourceNode}
                    targetNode={targetNode}
                    selectedEdge={selectedEdge}
                />
            ) : (
                <Box sx={{ pt: 2 }}>
                    <DeleteEdge id={id} />
                </Box>
            )}
        </>
    );
};

export default EdgeEditor;
