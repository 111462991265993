import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyD9QWJA58Ox4wwL83kjWdpp8LBS4dxHDNw',
    authDomain: 'prbx-firebase.firebaseapp.com',
    projectId: 'prbx-firebase',
    storageBucket: 'prbx-firebase.appspot.com',
    messagingSenderId: '54145996386',
    appId: '1:54145996386:web:bc1de201a2ce97d2bc633b',
    databaseURL:
        'https://prbx-firebase-default-rtdb.europe-west1.firebasedatabase.app',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const mAuth = getAuth(app);
export const db = getDatabase(app);

if (location.hostname == 'localhost') {
    connectAuthEmulator(mAuth, 'http://localhost:9099');
    connectDatabaseEmulator(db, 'localhost', 9000);
}
