import React, { useEffect, useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { ROUTES } from '@prbx/consts';
import { useIdToken } from 'react-firebase-hooks/auth';
import { mAuth } from '@prbx/firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { sendPasswordReset } from '@prbx/firebase/auth';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [user, loading] = useIdToken(mAuth);
    const [email, setEmail] = useState<string>('');
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const [redirectCountdown, setRedirectCountdown] = useState(4);

    const handleClick = () => {
        sendPasswordReset(email);
        setPasswordResetSent(true);
    };

    useEffect(() => {
        if (user) {
            navigate(ROUTES.DASHBOARD);
        }
    }, [user, loading]);

    useEffect(() => {
        if (passwordResetSent) {
            setTimeout(() => {
                setRedirectCountdown(redirectCountdown - 1);
            }, 1000);
        }
        if (redirectCountdown == 0) {
            navigate(ROUTES.DASHBOARD);
        }
    }, [passwordResetSent, redirectCountdown]);

    if (user) {
        return <></>;
    }

    if (passwordResetSent) {
        return (
            <Stack
                spacing={2}
                sx={{ maxWidth: '600px', margin: 'auto', mt: '32px' }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        mb: 2,
                    }}
                    data-testid="forgotpassword-password-reset-sent"
                >
                    Password reset sent
                </Typography>
                <Typography>
                    An email has been sent to <b>{email}</b>, with instructions
                    on how to reset your password.
                    <br />
                    <br />
                    You will be redirected in {redirectCountdown} seconds...
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: '600px', margin: 'auto', mt: '32px' }}
        >
            <Typography
                variant="h3"
                sx={{
                    mb: 2,
                }}
            >
                Forgot password?
            </Typography>
            <Typography sx={{ mb: '16px !important' }}>
                If you have forgotton your password, please enter your email
                address below and press the button to send a password reset
                email.
            </Typography>
            <TextField
                autoComplete="email"
                placeholder="Enter an email address..."
                onChange={(e) => setEmail(e.target.value)}
                data-testid="forgotpassword-email"
            ></TextField>
            <Button
                onClick={handleClick}
                variant="contained"
                fullWidth
                disabled={email.length == 0}
                data-testid="forgotpassword-send-password-reset"
            >
                Send password reset
            </Button>
        </Stack>
    );
};

export default ForgotPassword;
