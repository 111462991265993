import React, { useState } from 'react';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { db, mAuth } from '@prbx/firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import CreateWorkspace from '@prbx/components/Dashboard/CreateWorkspace';
import { ref, query, orderByChild, equalTo } from 'firebase/database';
import { useObject } from 'react-firebase-hooks/database';
import Workspaces from '@prbx/components/Dashboard/Workspaces/Workspaces';

const Dashboard = () => {
    const [user] = useAuthState(mAuth);
    const [dashboardTabValue, setDashboardTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setDashboardTabValue(newValue);
    };

    const workspacesRef = ref(db, 'workspaces');
    const workspaceQuery = query(
        workspacesRef,
        orderByChild('createdBy'),
        equalTo(user?.uid ?? '')
    );
    const [workspaceDetailSnapshots, workspaceDetailSnapshotsLoading] =
        useObject(workspaceQuery);
    const workspacesToDisplay = workspaceDetailSnapshots?.val();

    const workspaceSharedQuery = query(
        workspacesRef,
        orderByChild(`sharedWith/${user?.uid}`),
        equalTo(true)
    );
    const [
        workspaceDetailSharedSnapshots,
        workspaceDetailSharedSnapshotsLoading,
    ] = useObject(workspaceSharedQuery);
    const workspacesSharedToDisplay = workspaceDetailSharedSnapshots?.val();

    if (!user) {
        return <></>;
    }

    return (
        <Stack
            sx={{
                padding: '0 56px 0 56px',
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <Box>
                <Typography
                    variant="h3"
                    sx={{
                        mb: 4,
                    }}
                >
                    Welcome {user.displayName}!
                </Typography>
                <CreateWorkspace />
            </Box>
            <Tabs
                sx={{ mb: 2 }}
                value={dashboardTabValue}
                onChange={handleTabChange}
            >
                <Tab label="Recent workspaces" />
                <Tab
                    label="Shared with you"
                    // icon={<FolderSharedRoundedIcon />}
                    // iconPosition="start"
                    data-testid="dashboard-shared-with-you"
                />
            </Tabs>
            {dashboardTabValue == 0 && (
                <Box
                    sx={{
                        flex: '1 0',
                        overflow: 'auto',
                    }}
                >
                    <Workspaces
                        user={user}
                        workspaces={workspacesToDisplay}
                        loading={workspaceDetailSnapshotsLoading}
                    />
                </Box>
            )}
            {dashboardTabValue == 1 && (
                <Box
                    sx={{
                        flex: '1 0',
                        overflow: 'auto',
                    }}
                >
                    <Workspaces
                        user={user}
                        workspaces={workspacesSharedToDisplay}
                        loading={workspaceDetailSharedSnapshotsLoading}
                    />
                </Box>
            )}
        </Stack>
    );
};

export default Dashboard;
