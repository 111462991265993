import { PaletteMode } from '@mui/material';

export const getPalette = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                  // palette values for light mode
                  primary: {
                      main: '#00C0EC',
                  },
                  secondary: {
                      main: '#00C0EC',
                  },
                  success: {
                      main: '#4DEDBA',
                  },
                  info: {
                      main: '#4DD3F2',
                  },
                  warning: {
                      main: '#FFE34D',
                  },
                  error: {
                      main: '#FF7352',
                  },
                  contrastThreshold: 4,
                  tonalOffset: 0.2,
              }
            : {
                  // palette values for dark mode
                  primary: {
                      main: '#00C0EC',
                  },
                  secondary: {
                      main: '#00C0EC',
                  },
                  success: {
                      main: '#4DEDBA',
                  },
                  info: {
                      main: '#4DD3F2',
                  },
                  warning: {
                      main: '#FFE34D',
                  },
                  error: {
                      main: '#FF7352',
                  },
                  contrastThreshold: 4,
                  tonalOffset: 0.2,
              }),
    },
});
